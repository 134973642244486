import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    class: {},
    grade: {},
    school: {},
    redFlagCount: 0
};

export const RedFlagSlice = createSlice({
    name: 'redFlag',
    initialState,
    reducers: {
        setRedFlagTodayStat: (state, action) => {
            let { range, list } = action.payload;
            state[range].today = list;
        },
        setRedFlagWeekStat: (state, action) => {
            let { range, list } = action.payload;
            state[range].week = list;
        },
        setRedFlagMonthStat: (state, action) => {
            let { range, list } = action.payload;
            state[range].month = list;
        },
        setRedFlagTermStat: (state, action) => {
            let { range, list } = action.payload;
            state[range].term = list;
        },
        setRedFlagYearStat: (state, action) => {
            let { range, list } = action.payload;
            state[range].year = list;
        },
        setFlagCount: (state, action) => {
            state.redFlagCount = action.payload;
        }
    }
});

export const { setRedFlagTodayStat, setRedFlagWeekStat, setRedFlagMonthStat, setRedFlagTermStat, setRedFlagYearStat, setFlagCount } = RedFlagSlice.actions;
export default RedFlagSlice.reducer;
