import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import lodash from 'lodash';
import IdName from '@/components/IdName';
import { WeekSimpleNames } from '@/shared/constants';

export default function ScheduleInfo({ classType, curClass, dayOfWeek }) {
    const classSchedule = useSelector((state) => state.classSchedule);
    let classCount = classSchedule.term.classCount;
    let classList = {};
    let indexArray = [];
    for (let i = 1; i <= classCount; i++) {
        classList[`${i}`] = [];
        indexArray.push(i);
        for (let j = 1; j <= 7; j++) {
            classList[`${i}`][j - 1] = {
                dayOfWeek: j,
                classIndex: i,
                subjectId: 0,
                teacherid: 0
            };
        }
    }
    let classItem = null;
    for (let i = 0; i < classSchedule.week.length; i++) {
        classItem = classSchedule.week[i];
        classList[`${classItem.classIndex}`][classItem.dayOfWeek - 1] = classItem;
    }

    function getClassContent() {
        return (
            <div className="w-full flex flex-col">
                {indexArray.map((item) => {
                    return getClassScheduleRowData(item);
                })}
            </div>
        );
    }

    function getClassScheduleRowData(classIndex) {
        return (
            <div className="w-full h-32 flex flex-row flex-none items-center " key={`row_${classIndex}`}>
                <div className="w-20 flex-none h-full flex flex-row justify-center items-center">
                    <p className="text-2xl">{classIndex}</p>
                </div>
                <div className="grow h-full">
                    <div className="flex h-full flex-row items-center text-2xl">
                        {classList[`${classIndex}`].map((item) => {
                            return getClassSchedualItemComponent(item);
                        })}
                    </div>
                </div>
            </div>
        );
    }

    function getClassSchedualItemComponent(classItem) {
        //style={{backgroundColor:'#E1FBF7aF'}}
        //if (classItem.dayOfWeek === )
        let style = {};
        if (dayOfWeek === classItem.dayOfWeek) {
            style = { backgroundColor: '#E1FBF7aF' };
        }
        if (classItem.teacherId > 0) {
            return (
                <div className="w-1/7 h-full flex flex-col justify-center items-center" key={`${classItem.classIndex}_${classItem.dayOfWeek}`} style={style}>
                    <IdName nameType={9} nameId={classItem.subjectId} />
                    <IdName className="text-xl" nameType={1} nameId={classItem.teacherId} />
                </div>
            );
        } else {
            return <div className="w-1/7 h-full flex flex-col justify-center items-center" key={`${classItem.classIndex}_${classItem.dayOfWeek}`} style={style}></div>;
        }
    }

    function getClassScheduleRowLine() {
        let startOffset = 8;
        let offsetArray = [];
        for (let i = 0; i <= classCount - 2; i++) {
            offsetArray.push(startOffset);
            startOffset += 8;
        }
        return (
            <>
                {offsetArray.map((item) => {
                    return <div className="absolute w-full h-2px left-0 border-2 border-c-c border-dashed" style={{ top: `${item}rem` }} key={`rowLine_${item}`} />;
                })}
            </>
        );
    }

    //
    // <p>周一</p>
    // <p>09/12</p>
    //

    function getClassScheduleTitle() {
        let monday = classSchedule.weekInfo.monday;
        let weekTitle = [];
        let weekDay = null;
        for (let i = 0; i < 7; i++) {
            weekDay = new Date(monday + i * 24 * 3600000);
            weekTitle.push({
                day: `${lodash.padStart(weekDay.getDate(), 2, '0')}/${lodash.padStart(weekDay.getMonth() + 1, 2, '0')}`,
                name: WeekSimpleNames[i]
            });
        }
        return (
            <div className="flex h-full flex-row items-center text-2xl">
                {weekTitle.map((item, index) => {
                    return (
                        <div className="w-1/7 h-full flex flex-col justify-center items-center" key={`weekTitle_${index}`}>
                            <p>{item.name}</p>
                            <p>{item.day}</p>
                        </div>
                    );
                })}
            </div>
        );
    }

    return (
        <div className="w-full h-full p-4">
            <div className="w-full h-full flex flex-col">
                <div className="w-full h-32 flex flex-row flex-none items-center ">
                    <div className="w-20 flex-none h-full" />
                    <div className="grow h-full">{getClassScheduleTitle()}</div>
                </div>
                <div className="w-full grow overflow-y-auto">
                    <div className="relative">
                        {getClassContent()}
                        <div className="absolute w-full h-full top-0 left-0">
                            <div className="flex flex-row w-full h-full">
                                <div className="w-20 flex-none h-full" />
                                <div className="relative grow h-full border-2 border-c-c border-dashed">
                                    {getClassScheduleRowLine()}
                                    <div className="absolute w-2px h-full top-0 border-2 border-c-c border-dashed" style={{ left: '14.285%' }} />
                                    <div className="absolute w-2px h-full top-0 border-2 border-c-c border-dashed" style={{ left: '28.57%' }} />
                                    <div className="absolute w-2px h-full top-0 border-2 border-c-c border-dashed" style={{ left: '42.85%' }} />
                                    <div className="absolute w-2px h-full top-0 border-2 border-c-c border-dashed" style={{ left: '57.14%' }} />
                                    <div className="absolute w-2px h-full top-0 border-2 border-c-c border-dashed" style={{ left: '71.42%' }} />
                                    <div className="absolute w-2px h-full top-0 border-2 border-c-c border-dashed" style={{ left: '85.71%' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
