import homebtn from '@/assets/images/homebtn.png';
import redflowerbtn from '@/assets/images/redflowerbtn.png';
import momentbtn from '@/assets/images/momentbtn.png';
import flagbtn from '@/assets/images/flagbtn.png';
import minebtn from '@/assets/images/minebtn.png';

import leavebtn from '@/assets/images/leave.png';
import classschedule from '@/assets/images/classschedule.png';

import { useNavigate } from 'react-router-dom';
import { ShowMessage } from '@/components/MsgBox';
import { useDispatch } from 'react-redux';
import { setMineInfo } from '@/store/mine-reducer';
import { addSwipCardInfo } from '../../store/cardreaderreducer';
import { asyncCallNative, callNative } from 'hhjsshell';

function Tailer() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //0.136
    return (
        <div className="w-full flex-none h-tailer px-7 flex flex-row justify-center items-center">
            <div className="w-1/2 h-28 rounded-2xl px-10 flex flex-row justify-around items-center bg-white/50">
                <img
                    className="w-18 h-18 rounded-2xl"
                    src={homebtn}
                    onClick={() => {
                        onClick(navigate, 1);
                    }}
                    alt=""
                />
                <img
                    className="w-18 h-18 rounded-2xl"
                    src={redflowerbtn}
                    onClick={() => {
                        onClick(navigate, 2);
                    }}
                    alt=""
                />
                <img
                    className="w-18 h-18 rounded-2xl"
                    src={classschedule}
                    onClick={() => {
                        onClick(navigate, 3);
                    }}
                    alt=""
                />
                <img
                    className="w-18 h-18 rounded-2xl"
                    src={momentbtn}
                    onClick={() => {
                        onClick(navigate, 4);
                    }}
                    alt=""
                />
                <img
                    className="w-18 h-18 rounded-2xl"
                    src={leavebtn}
                    onClick={() => {
                        onClick(navigate, 5);
                    }}
                    alt=""
                />
                <img
                    className="w-18 h-18 rounded-2xl"
                    src={flagbtn}
                    onClick={() => {
                        onClick(navigate, 6);
                    }}
                    alt=""
                />
                <img
                    className="w-18 h-18 rounded-2xl"
                    src={minebtn}
                    onClick={() => {
                        onClick(navigate, 7);
                    }}
                    alt=""
                />
            </div>
        </div>
    );

    // function onAudioRecord(param) {
    //     if (param.state === 0) {
    //         console.log('开始录音');
    //         return {
    //             keepcallback: 1
    //         };
    //     } else {
    //         if (param.state === 1) {
    //             console.log(`正在录音，返回数据len=${param.length}`);
    //             return {
    //                 keepcallback: 1
    //             };
    //         } else {
    //             if (param.state === 2) {
    //                 console.log('结束录音');
    //             } else {
    //                 console.log(`param.result=${param.result}`);
    //             }
    //         }
    //     }
    // }

    // function onAudioStopRecord(param) {
    //     console.log(`onAudioStopRecord return param.result=${param.result}`);
    // }

    function onClick(navigate, index) {
        switch (index) {
            case 1:
                navigate('/home');
                break;
            case 2:
                navigate('/redflower');
                break;
            case 3:
                navigate('/classschedule');
                break;
            case 4:
                navigate('/moment');
                break;
            case 5:
                navigate('/leaveinfo');
                break;
            case 6:
                navigate('/redflag');
                // callNative(
                //     'appobject',
                //     'startAudioRecord',
                //     { channel: 2, sampleRate: 44100, bitPerSample: 16 },
                //     onAudioRecord
                // );
                // asyncCallNative('appobject', 'startAudioRecord', { mediaEncode: true }).then((data) => {
                //     let strData = data.data;
                //     let dataLen = data.length;
                //     if (data.result === 0) {
                //         console.log(`录音成功原始数据长度${dataLen} 字符串数据长度=${strData.length}`);
                //     } else {
                //         console.log(`录音出错，错误号${data.result} 错误码：${data.msg}`);
                //     }
                // });
                // ShowMessage('提示信息', '开始录音', 1, () => {
                //     // callNative('appobject', 'stopAudioRecord', {}, onAudioStopRecord);
                //     asyncCallNative('appobject', 'stopAudioRecord', {}).then((data) => {
                //         console.log('成功执行关闭函数');
                //     });
                // });
                break;
            case 7:
                dispatch(setMineInfo({ prepareToEnterMine: true }));
                /**
                 * debug
                 */
                // dispatch(addSwipCardInfo({ userId: 1000120 }));

                //开始人脸识别
                asyncCallNative('appobject', 'startFaceDetect', {}).then((data) => {
                    //let tips = `--------on face Recognize return userId=${data.userId} ----fileName=${data.fileName}`;
                    //console.log(tips);
                    if (data.userId > 0) {
                        dispatch(addSwipCardInfo({ userId: data.userId }));
                    } else {
                        dispatch(setMineInfo({ prepareToEnterMine: false }));
                    }
                });
                // ShowMessage('提示信息', '进入“我的”，请刷卡', 1, () => {
                //     dispatch(setMineInfo({ prepareToEnterMine: false }));
                //     // dispatch(addSwipCardInfo({ userId: 1000358 }));
                // });
                break;
            default:
                break;
        }
    }
}
export default Tailer;
