import Avatarpng from '@/assets/images/Avatar.png';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import IdName from '@/components/IdName';
import IdAvatar from '@/components/IdAvatar';
import lodash from 'lodash';
import utils from '@/shared/utils';
import { ChineseNumber } from '@/shared/constants';

export default function ScheduleList({ classList }) {
    const navigate = useNavigate();
    const keepaliveInfo = useSelector((state) => state.keepalive);
    const classSchedule = useSelector((state) => state.classSchedule);
    const appSettings = useSelector((state) => state.appSettingsInfo);
    const [curScheduleInfo, setCurScheduleInfo] = useState({
        type: 0, //当前上课类型   =0  课外休息   =1   课间休息    =2  正在上课
        curClassIndex: -1 //当前第几节课
    });
    let refClassContainer = useRef();

    //let nowDate = new Date('2023-10-11 14:36:00');
    let nowDate = new Date();
    //console.log(nowDate);
    let dayOfWeek = nowDate.getDay(); //当天星期几    1-7   周一到周日
    if (dayOfWeek === 0) {
        dayOfWeek = 7;
    }
    let itemWidth = 14; //每个课程宽度 = 14rem
    let itemGap = 1.5; //每个课程左右间隔 = 1.5rem
    let htmlFontSize = appSettings.fontSize;
    let todayClasses = {};
    for (let i = 0; i < classSchedule.week.length; i++) {
        if (classSchedule.week[i].dayOfWeek === dayOfWeek) {
            todayClasses[`${classSchedule.week[i].classIndex}`] = classSchedule.week[i];
        }
    }

    useEffect(() => {
        onTimer();
    }, [keepaliveInfo.timerId, classSchedule.week, classSchedule.term]);

    async function onTimer() {
        let curClassInfo = utils.getClassIntervalInfoFromDate(classSchedule.term, nowDate);
        if (
            curClassInfo.curClassType !== curScheduleInfo.type ||
            curClassInfo.curClassIndex !== curScheduleInfo.curClassIndex
        ) {
            setCurScheduleInfo({
                type: curClassInfo.curClassType,
                curClassIndex: curClassInfo.curClassIndex
            });
        }
        let offset = 0;
        if (curClassInfo.curClassIndex > 1) {
            let classItemWidth = itemWidth * htmlFontSize;
            let classItemGap = itemGap * htmlFontSize;
            offset = (classItemWidth + classItemGap) * (curClassInfo.curClassIndex - 1);
            offset -= classItemGap + classItemWidth; //显示上一个课程
        }
        refClassContainer.current.scrollLeft = offset; //${offset}rem`;
    }

    function onClick() {
        navigate('/classschedule');
    }

    function GetCurrentClassInfo() {
        if (curScheduleInfo.type === 0) {
            return (
                <div
                    className="h-full w-1/4 flex flex-row justify-center items-center flex-none flex-nowrap text-2xl"
                    onClick={onClick}
                    alt="classSchedule"
                >
                    <p className="text-4xl text-center">课外休息</p>
                </div>
            );
        } else {
            if (curScheduleInfo.type === 1) {
                return (
                    <div
                        className="h-full w-1/4 flex flex-row justify-center items-center flex-none flex-nowrap text-2xl"
                        onClick={onClick}
                        alt="classSchedule"
                    >
                        <p className="text-4xl text-center">课间休息</p>
                    </div>
                );
            } else {
                let userId = 0;
                let subjectId = 0;
                if (todayClasses[`${curScheduleInfo.curClassIndex}`]) {
                    userId = todayClasses[`${curScheduleInfo.curClassIndex}`].teacherId;
                    subjectId = todayClasses[`${curScheduleInfo.curClassIndex}`].subjectId;
                    if (!userId) {
                        userId = 0;
                    }
                    return (
                        <div
                            className="h-full w-1/4 flex flex-row items-center flex-none flex-nowrap text-2xl"
                            onClick={onClick}
                            alt="classSchedule"
                        >
                            <IdAvatar className="w-16 h-16 rounded-4xl mr-5 my-2" nameId={userId} />
                            <div>
                                <IdName className="text-lg" nameType={1} nameId={userId} />
                                <p className="text-lg">正在上课</p>
                                <IdName nameType={9} nameId={subjectId} />
                            </div>
                        </div>
                    );
                } else {
                    //if (timeValue >= classSchedule.term[`${i}`].classStart && timeValue <= classSchedule.term[`${i}`].classEnd) {
                    let startTime = classSchedule.term[`${curScheduleInfo.curClassIndex}`].classStart;
                    startTime = utils.getTimeStrFromSeconds(startTime);
                    let endTime = classSchedule.term[`${curScheduleInfo.curClassIndex}`].classEnd;
                    endTime = utils.getTimeStrFromSeconds(endTime);
                    return (
                        <div
                            className="h-full w-1/4 flex flex-row justify-center items-center flex-none flex-nowrap text-4xl"
                            onClick={onClick}
                            alt="classSchedule"
                        >
                            <div>
                                <p>自习课</p>
                                <p className="text-lg mt-2">{`${startTime} - ${endTime}`}</p>
                            </div>
                        </div>
                    );
                }
            }
        }
    }

    function getClassItem(classItem) {
        let startTime = classItem.classStart;
        startTime = utils.getTimeStrFromSeconds(startTime);
        let endTime = classItem.classEnd;
        endTime = utils.getTimeStrFromSeconds(endTime);
        let backgroundColor = '#FFFFFFAF';
        if (classItem.classIndex === curScheduleInfo.curClassIndex && curScheduleInfo.type === 2) {
            backgroundColor = '#0185F1AF';
        }
        if (classItem.subjectId > 0) {
            return (
                <div
                    className="w-60 mx-4 h-36 flex-none px-6 rounded-2xl flex flex-col justify-center items-start"
                    style={{ backgroundColor: `${backgroundColor}` }}
                    key={`classIndex_${classItem.classIndex}`}
                >
                    <p className="mb-1">{`第${ChineseNumber[classItem.classIndex]}节课`}</p>
                    <p className="mb-1">{`${startTime} - ${endTime}`}</p>
                    <IdName nameType={9} nameId={classItem.subjectId} className="mb-1" />
                    <IdName nameType={1} nameId={classItem.teacherId} />
                </div>
            );
        } else {
            return (
                <div
                    className="w-60 mx-4 h-36 flex-none px-6 rounded-2xl flex flex-col justify-center items-start"
                    style={{ backgroundColor: `${backgroundColor}` }}
                    key={`classIndex_${classItem.classIndex}`}
                >
                    <p className="mb-1">{`第${ChineseNumber[classItem.classIndex]}节课`}</p>
                    <p className="mb-1">{`${startTime} - ${endTime}`}</p>
                    <div className="flex flex-row justift-center items-center">
                        <div>
                            <p className="invisible mb-1">a</p>
                            <p className="invisible">a</p>
                        </div>
                        <p className="text-4xl">自习课</p>
                    </div>
                </div>
            );
        }
    }

    function getTodayClassItems(classItem) {
        let classes = [];
        for (let i = 1; i <= classSchedule.term.classCount; i++) {
            if (todayClasses[`${i}`]) {
                classes.push({
                    ...todayClasses[`${i}`],
                    classStart: classSchedule.term[i].classStart,
                    classEnd: classSchedule.term[i].classEnd
                });
            } else {
                classes.push({
                    classIndex: i,
                    subjectId: -1,
                    teacherId: -1,
                    classStart: classSchedule.term[i].classStart,
                    classEnd: classSchedule.term[i].classEnd
                });
            }
        }
        return (
            <div className="h-full flex flex-row items-center flex-nowrap justify-start text-lg">
                {classes.map((item) => {
                    return getClassItem(item);
                })}
            </div>
        );
    }

    return (
        <div className="w-full h-full flex flex-row p-6 flex-none">
            {GetCurrentClassInfo()}
            <div ref={refClassContainer} className="w-3/4 overflow-x-auto overflow-y-hidden">
                {getTodayClassItems()}
            </div>
        </div>
    );
}
