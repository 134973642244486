import cx from 'classnames';
import { useRef, useState } from 'react';

export default function AudioPlayer(props) {
  const [paused, setPaused] = useState(true);

  const refAudio = useRef();

  const handleClick = () => {
    if (refAudio.current.paused) {
      refAudio.current.currentTime = 0;
      refAudio.current.play();
      setPaused(false);
    } else {
      refAudio.current.pause();
      setPaused(true);
    }
  };

  const width = (props.duration / 60) * 300 + 100;

  return (
    <div
      className={cx(
        'flex h-[60px] items-center bg-white/80 px-4',
        'rounded-xl',
        {
          'justify-end': props.position === 'right'
        }
      )}
      style={{ width: width > 400 ? 400 : width }}
      onClick={handleClick}
    >
      {props.position === 'right' && (
        <div className="pr-4">{props.duration}"</div>
      )}
      <div
        className={cx('w-[48px] h-[48px] ', {
          'bg-[url("@/assets/images/trumpet-left.png")]':
            props.position === 'left',
          'bg-[url("@/assets/images/trumpet-right.png")]':
            props.position === 'right',
          'animate-[trumpet_2s_steps(4)_infinite]': !paused
        })}
      />
      {props.position === 'left' && (
        <div className="px-4">{props.duration}"</div>
      )}
      <audio ref={refAudio} src={props.src} onEnded={() => setPaused(true)} />
    </div>
  );
}
