import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { addEventListener, removeEventListener, asyncCallNative } from 'hhjsshell';
import { useEffect, useRef } from 'react';
import { queryBanpaiBindInfo, userLogin, keepalive } from '@/network/loginbindrequest.js';
import {
    queryUserClassInfo,
    queryUserSchoolInfo,
    queryClassLeaveMsgStatInfo,
    queryNameFromIds,
    queryMembersFromClassId
} from '@/network/schoolrequest';
import {
    queryRedFlowerRankListInRange,
    querySchoolTermInfo,
    queryClassLeaveStatListInRange,
    queryClassLeaveDetailListInRange,
    queryClassMomentListInRange,
    queryClassWeekScheduleInfo,
    querySchoolWeatherInfo,
    queryRedFlowerCountInRange,
    queryRedFlagCountInRange,
    queryClassStudentCardBindInfo
} from '@/network/classapprequest';
import config from '@/shared/appconfig.js';
import md5 from 'crypto-js/md5';
import { ShowWaiting, ShowMessage, HideWaiting, CloseMsgBox } from '@/components/MsgBox';
import {
    BindStatus,
    setBindStatus,
    setBindInfo,
    setCardBindInfo,
    setClassMembersInfo,
    setUserFaceInfo
} from '@/store/banpaireducer';
import { setKeepAliveInfo, setTimerId, increaseErrCount, increaseSyncCount } from '@/store/keepalivereducer';
import { setDeviceUUID, increaseDeviceIdRequestCnt } from '@/store/devicereducer';
import {
    LoginStatus,
    setLoginStatus,
    setUserInfo,
    setUserToken,
    setClassInfo,
    setSchoolInfo
} from '@/store/userinforeducer';
import { setScheduleWeekInfo, setScheduleTermInfo } from '@/store/classschedulereducer';
import { setIdNameInfo, setQueryInfo, clearQueryInfo, setAvatarInfo } from '@/store/idnamecachereducer';
import { setHotMoment } from '@/store/classmomentreducer';
import { setWeatherInfo } from '@/store/weatherinforeducer';
import { setLeaveTodayStat } from '@/store/classleavereducer';
import { addAttendanceTodayDetail } from '../../store/attendancereducer';
import {
    setFlowerTodayStat,
    setFlowerCount,
    setFlowerWeekStat,
    setFlowerMonthStat,
    setFlowerTermStat,
    setFlowerYearStat
} from '@/store/redflowerreducer';
import { setFlagCount } from '@/store/redflagreducer';
import { setClassMessageInfo } from '@/store/class-message-reducer';
import { setListener, addSwipCardInfo, removeSwipCardInfo } from '@/store/cardreaderreducer';
import { setMineInfo } from '@/store/mine-reducer';

import { Outlet } from 'react-router-dom';
import { setAppFontSize } from '../../store/appsettingsreducer';

let keepAlivePause = false; //是否暂停keepalive
let keepAliveEnable = false; //是否允许keepalive
let keepAliveLoopCount = 1; //keepalive多少次调用一次
let lastSyncMsgTime = 0;

function Launch() {
    //console.log('------------- on Launch() -------------------');

    const banpaiInfo = useSelector((state) => state.banpaiInfo);
    const userInfo = useSelector((state) => state.userInfo);
    const deviceInfo = useSelector((state) => state.deviceInfo);
    const keepaliveInfo = useSelector((state) => state.keepalive);
    const idNameCache = useSelector((state) => state.idNameCache);
    const cardReader = useSelector((state) => state.cardReader);
    const prepareToEnterMine = useSelector((state) => state.mine.prepareToEnterMine);

    //console.log(`-------- on Launch() --- cardReader.cardInfo=${cardReader.cardInfo.length}-------`);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    // console.log(`****** BindStatus.bindChanged=${BindStatus.bindChanged}******`);
    // console.log(`****** LoginStatus.loginSuccess=${LoginStatus.loginSuccess}******`);

    let cardBindInfoRef = useRef();
    cardBindInfoRef.current = banpaiInfo;

    useEffect(() => {
        if (deviceInfo.uuid){
            const density = window?.displayInfo?.density || 1;
            if (density !== 1) {
                const htmlFontSize = 16 / density;
                dispatch(setAppFontSize(htmlFontSize));
                document.documentElement.style.fontSize = `${htmlFontSize}px`;
            }
        }
    }, [deviceInfo.uuid]);

    useEffect(() => {
        onPageLoaded();
        return onPageUnLoaded;
    }, []);

    useEffect(() => {
        onCardReaderInit();
    }, [cardReader.listener]);

    useEffect(() => {
        onSwipCardInfoChanged();
    }, [cardReader.cardInfo]);

    //当班牌绑定状态发生改变时，发生在刚进入和绑定信息改变时
    useEffect(() => {
        if (deviceInfo.uuid && banpaiInfo.bindStatus >= BindStatus.noBind) {
            console.log(`----- 班牌绑定状态发生了改变--bindState=${banpaiInfo.bindStatus}------`);
            if (banpaiInfo.bindStatus === BindStatus.classBind) {
                console.log(`----- 班牌已绑定，开始登录------`);
                onUserlogin();
                HideWaiting();
            } else {
                if (banpaiInfo.bindStatus === BindStatus.noBind) {
                    console.log(`----- 班牌未绑定，重新走流程------`);
                    ShowWaiting('正在获取班牌绑定信息。。。');
                    queryBanpaiBindInfoFromServer();
                }
            }
        }
    }, [banpaiInfo.bindStatus]);

    useEffect(() => {
        checkDeviceUUID();
    }, [deviceInfo.requestCnt]);

    async function checkDeviceUUID() {
        //等待底层写入uuid
        if (!deviceInfo.uuid) {
            console.log(`-----query uuid from window *************`);
            if (window.deviceuuid) {
                console.log(`-----获取uuid=${window.deviceuuid}*****`);
                dispatch(setDeviceUUID(window.deviceuuid));
                dispatch(setBindStatus(BindStatus.noBind));
            } else {
                setTimeout(() => {
                    dispatch(increaseDeviceIdRequestCnt());
                }, 1000);
            }
        }
    }

    useEffect(() => {
        if (userInfo.loginStatus !== LoginStatus.noInit) {
            console.log(`----- 登录状态发生了改变 loginStatus=${userInfo.loginStatus}--------`);
            if (userInfo.loginStatus === LoginStatus.loginSuccess) {
                CloseMsgBox();
                console.log(`----- 成功登陆,开启keepalive--------`);
                startKeepAlive();
                console.log(`----- 进入首页/home--------`);
                startEnterHomePage();
            } else {
                if (userInfo.loginStatus === LoginStatus.loginfailed) {
                    CloseMsgBox();
                    ShowMessage('错误信息', '用户登录错误,请稍后再试', 1, () => {
                        dispatch(setLoginStatus(LoginStatus.loginRetry));
                    });
                }
                if (userInfo.loginStatus === LoginStatus.loginRetry) {
                    onUserlogin();
                }
            }
        }
    }, [userInfo.loginStatus]);

    useEffect(() => {
        if (keepaliveInfo.errCount >= 3) {
            //连续错误3次
            onNetWorkerror();
        }
    }, [keepaliveInfo.errCount]);

    useEffect(() => {
        if (keepaliveInfo.info.classId !== banpaiInfo.bindInfo.classId) {
            //班牌绑定信息发生了改变
            console.log(`-------班牌绑定信息发生了改变-------`);
            dispatch(setLoginStatus(LoginStatus.noLogin));
            dispatch(setBindStatus(BindStatus.noBind));
            stopKeepAlive();
            changeToRoute('/');
        }
    }, [keepaliveInfo.info]);

    useEffect(() => {
        keepAliveLoopCount--;
        if (keepAliveLoopCount < 0) {
            keepAliveLoopCount = 1;
            onKeepAlive();
        }
        onSyncMsgFromSvr();
    }, [keepaliveInfo.timerId]);

    useEffect(() => {
        //班级信息改变，更新学校信息，红花信息，请假信息， 风采信息， 课程表信息
        checkSchoolTermInfo();
        checkUserSchoolInfo();
    }, [userInfo.classInfo, keepaliveInfo.syncCount]);

    useEffect(() => {
        //班级信息改变，更新学校信息，红花信息，请假信息， 风采信息， 课程表信息
        checkClassStudentCardBindInfo();
        checkClassRedFlowerInfo();
        checkClassLeaveInfo();
        checkClassMomentInfo();
        checkClassScheduleInfo();
        checkSchoolWeatherInfo();
        checkRedFlowerCountInfo();
        checkRedFlagCountInfo();
        checkClassMsgInfo();
    }, [userInfo.schoolInfo]);

    useEffect(() => {
        onQueryNameByIds();
    }, [idNameCache.query]);

    async function startEnterHomePage() {
        checkUserClassInfo();
        navigate('/home');
    }

    function stopKeepAlive() {
        keepAliveEnable = false;
    }

    function startKeepAlive() {
        keepAliveEnable = true;
    }

    //检测班级信息
    async function checkUserClassInfo() {
        if (userInfo.loginStatus === LoginStatus.loginSuccess && userInfo.token) {
            console.log(`---开始检测班级信息 classId = ${userInfo.classInfo.id}`);
            if (userInfo.classInfo.id && userInfo.classInfo.id === banpaiInfo.bindInfo.classId) {
                let interval = Date.now() - userInfo.classInfo.lastQueryTime;
                if (interval < 600000) {
                    //10分钟从服务器更新一次班级信息
                    return;
                }
            }
            let res = await queryUserClassInfo(userInfo.token, banpaiInfo.bindInfo.classId);
            if (res && res.result === 0) {
                res.data.lastQueryTime = Date.now();
                dispatch(setClassInfo(res.data));
            } else {
                console.log(`----queryUserClassInfo return result=${res.result} msg=${res.msg}`);
            }
        }
    }

    //检测学校信息
    async function checkUserSchoolInfo() {
        if (userInfo.classInfo.id) {
            let res = await queryUserSchoolInfo(userInfo.token, userInfo.classInfo.schoolId);
            if (res && res.result === 0) {
                dispatch(setSchoolInfo(res.data));
            } else {
                console.log(`----queryUserSchoolInfo return result=${res.result} msg=${res.msg}`);
            }
        }
    }

    //检测学生绑卡信息
    async function checkClassStudentCardBindInfo() {
        //console.log(`----  ++++++++++  checkClassStudentCardBindInfo --classId=${userInfo.classInfo.id}---`);
        if (userInfo.classInfo.id) {
            //首先获取班级学生列表
            let res = await queryMembersFromClassId(userInfo.token, userInfo.classInfo.id);
            if (res && res.result === 0) {
                dispatch(setClassMembersInfo(res.data));
                let ids = [];
                for (let i = 0; i < res.data.length; i++) {
                    ids.push(res.data[i].userId);
                }
                if (ids.length > 0) {
                    //获取用户绑定信息
                    res = await queryNameFromIds(userInfo.token, 10, ids);
                    if (res && res.result === 0) {
                        let cardList = [];
                        let faceList = [];
                        for (let i = 0; i < res.data.length; i++) {
                            cardList.push({
                                userId: res.data[i].userId,
                                cardType: res.data[i].cardType,
                                cardNo: res.data[i].cardNo,
                                cardExt: res.data[i].cardExt
                            });
                            faceList.push({
                                userId: res.data[i].userId,
                                faceRes: res.data[i].faceRes,
                                face: res.data[i].face
                            });
                        }
                        dispatch(setCardBindInfo(cardList));
                        dispatch(setUserFaceInfo(faceList));
                        asyncCallNative('appobject', 'saveFaceList', { imageList: faceList });
                    } else {
                        console.log(`----queryNameFromIds return result=${res.result} msg=${res.msg}`);
                    }
                }
                // res = await queryClassStudentCardBindInfo(userInfo.token, userInfo.classInfo.id);
                // if (res && res.result === 0) {
                //     dispatch(setCardBindInfo(res.data));
                // } else {
                //     4console.log(`----queryClassStudentCardBindInfo return result=${res.result} msg=${res.msg}`);
                // }
            } else {
                console.log(`----queryMembersFromClassId return result=${res.result} msg=${res.msg}`);
            }
        }
    }

    //检测班级红花信息
    async function checkClassRedFlowerInfo() {
        if (userInfo.classInfo.id) {
            let res = await queryRedFlowerRankListInRange(
                userInfo.token,
                userInfo.classInfo.schoolId,
                userInfo.classInfo.gradeId,
                userInfo.classInfo.id,
                1,
                10
            );
            if (res && res.result === 0) {
                dispatch(setFlowerTodayStat({ range: 'class', list: res.data }));
            } else {
                console.log(`----queryRedFlowerRankListInRange return result=${res?.result} msg=${res?.msg}`);
            }
        }
    }

    //检测班级请假信息
    async function checkClassLeaveInfo() {
        if (userInfo.classInfo.id) {
            let res = await queryClassLeaveStatListInRange(
                userInfo.token,
                userInfo.classInfo.schoolId,
                userInfo.classInfo.gradeId,
                userInfo.classInfo.id,
                0
            );
            if (res && res.result === 0) {
                dispatch(setLeaveTodayStat(res.data));
            } else {
                console.log(
                    `----queryClassLeaveStatListInRange return result=${res?.result} msg=${res?.msg}`
                );
            }
        }
    }

    //检测班级风采信息
    async function checkClassMomentInfo() {
        if (userInfo.classInfo.id) {
            let res = await queryClassMomentListInRange(
                userInfo.token,
                userInfo.classInfo.schoolId,
                userInfo.classInfo.gradeId,
                userInfo.classInfo.id,
                30,
                10
            );
            if (res && res.result === 0) {
                dispatch(setHotMoment(res.data));
            } else {
                console.log(`----queryClassMomentListInRange return result=${res?.result} msg=${res?.msg}`);
            }
        }
    }

    //检测班级课程表信息
    async function checkClassScheduleInfo() {
        if (userInfo.classInfo.id) {
            let res = await queryClassWeekScheduleInfo(
                userInfo.token,
                userInfo.classInfo.schoolId,
                userInfo.classInfo.gradeId,
                userInfo.classInfo.id
            );
            if (res && res.result === 0) {
                dispatch(
                    setScheduleWeekInfo({
                        week: res.data,
                        weekIndex: res.weekIndex,
                        monday: res.monday,
                        sunday: res.sunday
                    })
                );
            } else {
                console.log(`----queryClassWeekScheduleInfo return result=${res?.result} msg=${res?.msg}`);
            }
        }
    }

    //检测获取学校学期信息
    async function checkSchoolTermInfo() {
        if (userInfo.classInfo.id) {
            let res = await querySchoolTermInfo(userInfo.token, userInfo.classInfo.schoolId);
            if (res && res.result === 0) {
                let termInfo = {};
                if (res.data.classTimes) {
                    res.data.classTimes.map((item) => {
                        termInfo[`${item.classIndex}`] = item;
                    });
                    termInfo.classCount = res.data.classTimes.length;
                    termInfo.startTime = res.data.startTime;
                    termInfo.totalWeeks = res.data.totalWeeks;
                    dispatch(setScheduleTermInfo(termInfo));
                }
            } else {
                console.log(`----querySchoolTermInfo return result=${res?.result} msg=${res?.msg}`);
            }
        }
    }

    //检测获取学校天气信息
    async function checkSchoolWeatherInfo() {
        if (userInfo.schoolInfo.id) {
            let res = await querySchoolWeatherInfo(
                userInfo.token,
                userInfo.schoolInfo.province,
                userInfo.schoolInfo.city,
                userInfo.schoolInfo.county
            );
            if (res && res.result === 0) {
                dispatch(
                    setWeatherInfo({
                        city: res.city,
                        condition: res.condition
                    })
                );
            } else {
                console.log(`----querySchoolWeatherInfo return result=${res?.result} msg=${res?.msg}`);
            }
        }
    }

    //检测获取红花总数信息
    async function checkRedFlowerCountInfo() {
        if (userInfo.schoolInfo.id) {
            let res = await queryRedFlowerCountInRange(
                userInfo.token,
                userInfo.classInfo.schoolId,
                userInfo.classInfo.gradeId,
                userInfo.classInfo.id,
                4
            );
            if (res && res.result === 0) {
                dispatch(setFlowerCount(res.data.result));
            } else {
                console.log(`----queryRedFlowerCountInRange return result=${res?.result} msg=${res?.msg}`);
            }
        }
    }
    //检测获取红旗总数信息
    async function checkRedFlagCountInfo() {
        if (userInfo.schoolInfo.id) {
            let res = await queryRedFlagCountInRange(
                userInfo.token,
                userInfo.classInfo.schoolId,
                userInfo.classInfo.gradeId,
                userInfo.classInfo.id,
                4
            );
            if (res && res.result === 0) {
                dispatch(setFlagCount(res.data.result));
            } else {
                console.log(`----queryRedFlagCountInRange return result=${res?.result} msg=${res?.msg}`);
            }
        }
    }

    async function checkClassMsgInfo() {
        if (userInfo?.classInfo?.id) {
            const res = await queryClassLeaveMsgStatInfo({
                token: userInfo.token,
                classId: userInfo.classInfo.id
            });
            if (res?.result === 0) {
                dispatch(setClassMessageInfo({ messageList: res.data }));
            }
        }
    }

    //当有id需要获取名字时调用
    async function onQueryNameByIds() {
        if (idNameCache.query.length > 0) {
            let ids = idNameCache.query;
            dispatch(clearQueryInfo());
            let idsObject = {};
            for (let i = 0; i < ids.length; i++) {
                if (!idsObject[`${ids[i].type}`]) {
                    idsObject[`${ids[i].type}`] = [];
                }
                idsObject[`${ids[i].type}`].push(ids[i].id);
            }
            let res = null;
            let type = 0;
            for (let strType in idsObject) {
                type = parseInt(strType);
                res = await queryNameFromIds(userInfo.token, type, idsObject[strType]);
                if (res && res.data) {
                    for (let i = 0; i < res.data.length; i++) {
                        dispatch(
                            setIdNameInfo({
                                type,
                                id: res.data[i].id ? res.data[i].id : res.data[i].userId,
                                value: res.data[i].name
                            })
                        );
                        if (type === 1) {
                            dispatch(
                                setAvatarInfo({
                                    id: res.data[i].userId,
                                    avatar: res.data[i].avatar ? res.data[i].avatar : ''
                                })
                            );
                        }
                    }
                }
            }
        }
    }

    async function onKeepAlive() {
        if (keepAlivePause || !keepAliveEnable) {
            return;
        }
        keepAlivePause = true;
        let res = await keepalive(deviceInfo.uuid, userInfo.token);
        keepAliveLoopCount = 1;
        keepAlivePause = false;
        //console.log('-----onKeepAlive----- res=', res);
        if (!res || res.result !== 0) {
            console.log(`-----keep alive result=${res?.result} msg=${res?.msg}`);
            dispatch(increaseErrCount());
        } else {
            dispatch(setUserToken(res.token));
            dispatch(
                setKeepAliveInfo({
                    time: Date.now(),
                    classId: res.classId,
                    tickcount: res.tickcount
                })
            );
        }
    }

    async function onSyncMsgFromSvr() {
        let nowDate = Date.now();
        if (lastSyncMsgTime === 0) {
            lastSyncMsgTime = nowDate;
        }
        let timeDiff = nowDate - lastSyncMsgTime;
        if (timeDiff >= 30000) {
            //30秒刷新一次
            lastSyncMsgTime = nowDate;
            dispatch(increaseSyncCount());
        }
    }

    async function onPageLoaded() {
        console.log('------------- on launch onPageLoaded -------------------');
        let timerId = 0;
        setInterval(() => {
            dispatch(setTimerId(++timerId));
        }, 6000);
    }

    async function onUserlogin() {
        if (banpaiInfo.bindStatus === BindStatus.classBind) {
            let bindInfo = banpaiInfo.bindInfo;
            let nowDate = Date.now();
            let srcSecret = `${bindInfo.update}${bindInfo.uuid}${bindInfo.md5}${nowDate}`;
            let appSecret = md5(srcSecret).toString();
            ShowWaiting('正在登陆服务器。。。');
            let userInfo = await userLogin(deviceInfo.uuid, nowDate, appSecret);
            if (userInfo) {
                if (userInfo.result === 0) {
                    //登陆成功
                    console.log('------------登录成功------------------');
                    dispatch(setLoginStatus(LoginStatus.loginSuccess));
                    dispatch(setUserInfo(userInfo.userInfo));
                    dispatch(setUserToken(userInfo.token));
                } else {
                    //登陆失败
                    console.log('------------登录失败------------------');
                    dispatch(setLoginStatus(LoginStatus.loginfailed));
                    dispatch(setUserInfo(null));
                }
            } else {
                // 网络出错
                onNetWorkerror();
            }
        }
    }

    async function changeToRoute(route) {
        CloseMsgBox();
        navigate(route);
    }

    async function onNoBind() {
        console.log('------------服务器返回未绑定 跳转到/bind-------------------');
        stopKeepAlive();
        changeToRoute('/bind');
    }
    //当网络出错时调用
    async function onNetWorkerror() {
        console.log('------------网络错 跳转到/bind------------------');
        dispatch(setLoginStatus(LoginStatus.noLogin));
        dispatch(setBindStatus(BindStatus.noBind));
        stopKeepAlive();
        changeToRoute('/bind');
    }

    //当班牌绑定状态发生改变时调用
    async function queryBanpaiBindInfoFromServer() {
        console.log(`------------开始请求班牌绑定信息 uuid=${deviceInfo.uuid}------------------`);
        let retBindInfo = await queryBanpaiBindInfo(deviceInfo.uuid);
        if (retBindInfo) {
            if (retBindInfo.result === 0) {
                //成功绑定
                console.log(
                    `------------请求班牌绑定信息返回成功${deviceInfo.uuid}-classId=${retBindInfo.classId}-----------------`
                );
                delete retBindInfo.result;
                delete retBindInfo.msg;
                dispatch(setBindInfo(retBindInfo));
                dispatch(setBindStatus(BindStatus.classBind));
            } else {
                //未绑定
                onNoBind();
            }
        } else {
            // 网络出错
            onNetWorkerror();
        }
    }

    //当主界面退出时时调用
    async function onPageUnLoaded() {
        if (cardReader.listener) {
            removeEventListener('onReadCardEvent', cardReader.listener);
            dispatch(setListener(null));
        }
    }

    //当需要初始化读卡器时调用
    function onCardReaderInit() {
        if (!cardReader.listener) {
            addEventListener('onReadCardEvent', onCardReaderSwipEvent);
            dispatch(setListener(1));
        }
    }

    function onCardReaderSwipEvent(event, cardInfo) {
        console.log(`------- onCardReaderSwipEvent event=${event}, cardInfo=${cardInfo}--------`);
        let userId = 0;
        if (cardBindInfoRef.current.cardInfo) {
            for (let i = 0; i < cardBindInfoRef.current.cardInfo.length; i++) {
                if (
                    cardBindInfoRef.current.cardInfo[i].cardType === cardInfo.cardType &&
                    cardBindInfoRef.current.cardInfo[i].cardNo === cardInfo.cardNumber
                ) {
                    userId = cardBindInfoRef.current.cardInfo[i].userId;
                    break;
                }
            }
        }
        dispatch(
            addSwipCardInfo({
                ...cardInfo,
                userId
            })
        );
    }

    function onSwipCardInfoChanged() {
        //console.log('----onSwipCardInfoChanged----');
        if (cardReader.cardInfo && cardReader.cardInfo.length > 0) {
            //console.log('----onSwipCardInfoChanged----111111111111');
            const cardInfo = cardReader.cardInfo[0];
            if (prepareToEnterMine) {
                if (location.pathname.startsWith !== '/mine') {
                    dispatch(setMineInfo({ studentId: cardInfo.userId, prepareToEnterMine: false }));
                    asyncCallNative('appobject', 'closeFaceDetect', {});
                    navigate('/mine');
                }
            } else {
                //console.log('----onSwipCardInfoChanged---222222222222');
                console.log(location.pathname);
                if (location.pathname.startsWith('/home')) {
                    //console.log('----onSwipCardInfoChanged---333333333333');
                    dispatch(
                        addAttendanceTodayDetail({
                            userId: cardInfo.userId,
                            type: cardInfo.cardNo ? 0 : 1,
                            cardNo: cardInfo.cardNo,
                            cardType: cardInfo.cardType,
                            time: Math.floor(Date.now() / 1000)
                        })
                    );
                    dispatch(removeSwipCardInfo());
                }
            }
        }
    }

    return (
        <div
            className={`w-screen h-screen flex justify-center text-24 `}
            style={{
                backgroundImage: `url(${config.resInfo.webUrl}/images/homebg.jpg)`,
                backgroundSize: 'cover'
            }}
        >
            <Outlet />
        </div>
    );
}

export default Launch;
