//import classNames from 'classnames';
import Avatarpng from '@/assets/images/Avatar.png';
import config from '@/shared/appconfig';
import { useSelector, useDispatch } from 'react-redux';
import { setIdNameInfo, setQueryInfo, clearQueryInfo, setAvatarInfo } from '@/store/idnamecachereducer';
export default function IDAvatar({ className, nameId }) {
    const idNameCache = useSelector((state) => state.idNameCache);
    let dispatch = useDispatch();
    let avatar = Avatarpng;
    if (idNameCache.avatar[`${nameId}`] !== undefined) {
        if (!idNameCache.avatar[`${nameId}`]) {
            return <img className={className} src={Avatarpng} alt="" />;
        } else {
            avatar = idNameCache.avatar[`${nameId}`];
            let url = `${config.resInfo.resUrl}/${avatar}`;
            return <img className={className} src={`${url}`} alt="" />;
        }
    } else {
        setTimeout(() => {
            dispatch(setQueryInfo({ type: 1, id: nameId }));
        }, 0);
        return <img className={className} src={avatar} alt="" />;
    }
}
