import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    city: {},
    condition: {}
};

export const WeatherInfoSlice = createSlice({
    name: 'weatherInfo',
    initialState,
    reducers: {
        setWeatherInfo: (state, action) => {
            const { city, condition } = action.payload;
            state.city = city;
            state.condition = condition;
        }
    }
});

export const { setWeatherInfo } = WeatherInfoSlice.actions;
export default WeatherInfoSlice.reducer;
