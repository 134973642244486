import { WeekDays } from '@/shared/constants';
import { useSelector } from 'react-redux';
import config from '@/shared/appconfig';
import lodash from 'lodash';

function Header() {
    const userInfo = useSelector((state) => state.userInfo);
    const weatherInfo = useSelector((state) => state.weatherInfo);
    let schoolName = '';
    if (userInfo.schoolInfo) {
        schoolName = userInfo.schoolInfo.name;
        if (userInfo.schoolInfo.comment) {
            schoolName = userInfo.schoolInfo.comment;
        }
    }
    let nowDate = new Date();
    let fullYear = nowDate.getFullYear();
    let nowDay = nowDate.getDate();
    let nowMonth = nowDate.getMonth() + 1;
    let dayOfWeek = nowDate.getDay();

    function getWeatherInfo() {
        if (!lodash.isEmpty(weatherInfo?.condition)) {
            let iconUrl = `${config.resInfo.resUrl}/hhres/10009/icon/W${weatherInfo.condition.icon}.png`;
            //hhres/10009/icon/W0.png
            return (
                <div className="flex flex-col items-center justify-center">
                    <div className="flex flex-row items-center">
                        <p className="mt-2 text-4xl mr-6">{weatherInfo.condition.condition}</p>
                        <img className="w-16 h-16" src={iconUrl} />
                        <p className="ml-6 text-6xl">{`${weatherInfo.condition.temp}°C`}</p>
                    </div>
                    <p className="mt-2 text-xl">{weatherInfo.condition.tips}</p>
                </div>
            );
        }
        return null;
    }

    return (
        //0.122
        <div className="relative w-a-w h-title ml-l-gap">
            <div className="px-3 w-full h-full flex-none flex flex-col justify-center items-start">
                <p className="w-full text-2xl">{fullYear}</p>
                <div className="w-full flex flex-row justity-between items-center mt-3">
                    <div className="w-full flex flex-row justity-start items-end">
                        <p className="text-4xl mr-5">{`${nowMonth}月${nowDay}日`}</p>
                        <p className="text-4xl">{WeekDays[dayOfWeek]}</p>
                    </div>
                    {/* <div className="flex flex-row justity-between items-center">
                        <p>图标1</p>
                        <p>图标2</p>
                    </div> */}
                </div>
            </div>
            <div className="absolute left-0 top-0 w-full h-full flex flex-row justify-center">
                <p className="text-5xl font-bold self-center text-3-3 mr-70">{schoolName}</p>
            </div>
            <div className="absolute left-0 top-0 w-full h-full flex flex-row justify-end px-3">
                {getWeatherInfo()}
            </div>
        </div>
    );
}
export default Header;
