import { asyncCallNative } from 'hhjsshell';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import IdName from '@/components/IdName';
import IdAvatar from '@/components/IdAvatar';
import { useNavigate } from 'react-router-dom';
import { addSwipCardInfo } from '../../../store/cardreaderreducer';

export default function AttendanceStatInfo() {
    const navigate = useNavigate();
    const attendanceInfo = useSelector((state) => state.attendance.today);
    const dispatch = useDispatch();

    function onClick() {
        navigate('/leaveinfo');
    }

    function onAttendanceClick(e) {
        e.stopPropagation();
        //开始人脸识别
        asyncCallNative('appobject', 'startFaceDetect', {}).then((data) => {
            if (data.userId > 0) {
                dispatch(addSwipCardInfo({ userId: data.userId }));
            }
        });
    }
    function getAttendanceDetailString(detailInfo) {
        let date = new Date(detailInfo.time * 1000);
        let hour = date.getHours();
        if (hour < 10) {
            hour = `0${hour}`;
        } else {
            hour = `${hour}`;
        }
        let minute = date.getMinutes();
        if (minute < 10) {
            minute = `0${minute}`;
        } else {
            minute = `${minute}`;
        }
        let second = date.getSeconds();
        if (second < 10) {
            second = `0${second}`;
        } else {
            second = `${second}`;
        }
        //let day = date.getDate();
        //let month = date.getMonth() + 1;
        let timeString = `${hour}:${minute}:${second}`;
        if (detailInfo.type === 0) {
            //打卡考勤
            return `${timeString}通过打卡考勤`;
        } else {
            if (detailInfo.type === 1) {
                //人脸考勤
                return `${timeString}通过人脸考勤`;
            }
        }
    }

    function getAttendanceDetailComponent(index) {
        if (attendanceInfo.detail && attendanceInfo.detail[index]) {
            return (
                <div className="flex flex-row justify-start items-center" key={`detail_${index}`}>
                    <IdAvatar
                        className="w-8 h-8 rounded-2xl mr-2"
                        nameId={attendanceInfo.detail[index].userId}
                    />
                    <IdName
                        className="text-xl mr-2"
                        nameId={attendanceInfo.detail[index].userId}
                        nameType={1}
                    />
                    <p>{getAttendanceDetailString(attendanceInfo.detail[index])}</p>
                </div>
            );
        }
    }

    return (
        <div className="w-full h-b-h px-3 py-2" onClick={onClick} alt="leaveInfo">
            <div className="bg-white/40 w-full h-full rounded-2xl flex flex-row items-center justify-start">
                <div className="w-24 h-full rounded-l-2xl flex flex-col justify-center items-center bg-lime-600/80">
                    <p className="w-8 text-3xl text-white">今日考勤</p>
                </div>
                <div className="grow rounded-r-2xl h-full flex flex-col justify-start items-start">
                    <div className="w-full h-1/2 flex flex-row items-center justify-between mt-4">
                        <div className="flex flex-col items-start justify-center h-full grow ml-6">
                            <div className="flex flex-row justift-start items-end text-blue-600">
                                <p className="text-5xl">{attendanceInfo.stat.attance}</p>
                                <p className="text-xl">{`人已签到(共${attendanceInfo.stat.total}人)`}</p>
                            </div>
                            <div className="mt-4">
                                <p className="text-2xl">{`未签到${attendanceInfo.stat.absent}人/迟到${attendanceInfo.stat.late}人`}</p>
                            </div>
                        </div>
                        <div
                            className="rounded-2xl w-24 h-16 bg-blue-600 mx-3 flex flex-row justify-center items-center"
                            onClick={onAttendanceClick}
                        >
                            <p className="text-white text-2xl">签到</p>
                        </div>
                    </div>
                    <div className="w-full grow flex flex-col justify-evenly items-start ml-6 text-xl">
                        {getAttendanceDetailComponent(0)}
                        {getAttendanceDetailComponent(1)}
                    </div>
                </div>
            </div>
        </div>
    );
}
