import Avatarpng from '@/assets/images/Avatar.png';
import flowerAvatarpng from '@/assets/images/flowerAvatar.png';
import flowericonpng from '@/assets/images/flowericon.png';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import IdName from '@/components/IdName';
import IdAvatar from '@/components/IdAvatar';
export default function FlowerRankList() {
    const navigate = useNavigate();
    const redFlower = useSelector((state) => state.redFlower.class);

    function getFlowerTop3() {
        let topCount = 0;
        if (redFlower.today) {
            topCount = redFlower.today.length;
        }
        if (topCount > 3) {
            topCount = 3;
        }
        let top3 = [];
        for (let i = 0; i < topCount; i++) {
            top3.push(redFlower.today[i]);
        }
        return (
            <>
                {top3.map((item) => {
                    return <FlowerItem key={`item_${item.student}`} item={item} />;
                })}
            </>
        );
    }

    function onClick() {
        navigate('/redflower');
    }

    return (
        <div className="w-full h-c-h px-3 py-2" onClick={onClick} alt="redflower">
            <div className="bg-white/60 w-full h-full rounded-2xl flex flex-col justify-center items-start">
                <div className="mx-4 mb-4 flex flex-row justify-start items-center">
                    <img className="w-7 h-7 mr-6 ml-4" src={flowerAvatarpng}></img>
                    <p className="text-3xl">本周红花TOP3</p>
                </div>
                {getFlowerTop3()}
            </div>
        </div>
    );
}

function FlowerItem({ item }) {
    return (
        <div className="flex flex-row items-center w-full">
            <IdAvatar className="w-16 h-16 rounded-4xl mx-5 my-2" nameId={item.student} />
            <IdName className="grow text-2xl" nameId={item.student} nameType={1} />
            <p className="text-3xl mr-6">{item.count}</p>
            <img className="w-9 h-9 mr-6" src={flowericonpng}></img>
        </div>
    );
}
