//import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setIdNameInfo, setQueryInfo, clearQueryInfo, setAvatarInfo } from '@/store/idnamecachereducer';
export default function IdName({ className, nameType, nameId }) {
    const idNameCache = useSelector((state) => state.idNameCache);
    let dispatch = useDispatch();
    let showName = `#${nameId}`;
    if (nameId) {
        if (idNameCache.ids[`${nameType}`] && idNameCache.ids[`${nameType}`][`${nameId}`] !== undefined) {
            if (idNameCache.ids[`${nameType}`][`${nameId}`]) {
                showName = idNameCache.ids[`${nameType}`][`${nameId}`];
            }
        } else {
            setTimeout(() => {
                dispatch(setQueryInfo({ type: nameType, id: nameId }));
            }, 0);
        }
    }
    return <p className={className}>{showName}</p>;
}
