import ScheduleList from '@/components/scheduleList';
export default function ClassScheduleInfo() {
    return (
        <div className="w-full h-b-h py-2 px-1">
            <div className="bg-white/60 w-full h-full rounded-2xl">
                <ScheduleList />
            </div>
        </div>
    );
}
