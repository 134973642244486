import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
export default function Fan({
    className,
    width,
    innerWidth,
    borderWidth,
    borderColor,
    angle,
    color,
    bkColor,
    innerColor
}) {
    let offset = 0;
    let widthSuffix = '';
    let widthNumber = 0;
    let innerWidthNumber = 0;
    let posIndex = width.indexOf('px');
    if (posIndex < 0) {
        posIndex = width.indexOf('rem');
    }
    widthNumber = parseFloat(width.substring(0, posIndex));
    widthSuffix = width.substring(posIndex);
    if (innerWidth) {
        posIndex = innerWidth.indexOf('px');
        if (posIndex < 0) {
            posIndex = innerWidth.indexOf('rem');
        }
        innerWidthNumber = parseFloat(innerWidth.substring(0, posIndex));
    }
    offset = (widthNumber - innerWidthNumber) / 2;
    angle = angle % 360;
    let canvasRef = useRef();
    const appSettings = useSelector((state) => state.appSettingsInfo);

    useEffect(() => {
        if (canvasRef.current) {
            let contex = canvasRef.current.getContext('2d');
            if (contex) {
                onDrawFan(contex);
            }
        }
    }, []);

    function onDrawFan(contex) {
        if (widthSuffix === 'rem') {
            //转换成px
            widthSuffix = 'px';
            widthNumber *= appSettings.fontSize;
            innerWidthNumber *= appSettings.fontSize;
        }
        let radianAngle = (angle * Math.PI) / 180;
        let outerRadius = widthNumber / 2;
        let innerRadius = innerWidthNumber / 2;
        let shadowWidth = 4;
        //绘制扇形
        contex.save();
        contex.clearRect(0, 0, widthNumber, widthNumber);
        contex.strokeStyle = '#ffffffaf';
        //contex.fillStyle = color;
        contex.lineWidth = 2;
        contex.shadowBlur = shadowWidth;
        contex.shadowColor = '#bbbbbb';
        // let fillColorGradient = contex.createLinearGradient(
        //     -outerRadius,
        //     -outerRadius,
        //     outerRadius,
        //     outerRadius
        // );
        //变换中心点
        contex.translate(outerRadius, outerRadius);
        //先画底部实心园
        let fillColorGradient = contex.createRadialGradient(0, 0, innerRadius, 0, 0, outerRadius);
        fillColorGradient.addColorStop(0, '#868C8B6F');
        fillColorGradient.addColorStop(1, '#ABB2B16F');
        contex.fillStyle = fillColorGradient;
        contex.beginPath();
        contex.arc(0, 0, outerRadius - shadowWidth, 0, 2 * Math.PI, false);
        contex.closePath();
        contex.fill();
        //开始画三角扇形
        contex.fillStyle = color;
        contex.beginPath();
        contex.moveTo(0, 0);
        contex.lineTo(0, -(outerRadius - shadowWidth));
        contex.arc(0, 0, outerRadius - shadowWidth, -Math.PI / 2, radianAngle - Math.PI / 2, false);
        contex.moveTo(0, 0);
        contex.closePath();
        contex.fill();
        //画边
        // contex.beginPath();
        // contex.arc(0, 0, innerRadius, 0, 2 * Math.PI, false);
        // contex.closePath();
        // contex.stroke();
        contex.beginPath();
        contex.arc(0, 0, outerRadius - shadowWidth, 0, 2 * Math.PI, false);
        contex.closePath();
        contex.stroke();
        //画扇形边
        contex.moveTo(0, -innerRadius);
        contex.lineTo(0, -(outerRadius - shadowWidth));
        contex.arc(0, 0, outerRadius - shadowWidth, -Math.PI / 2, radianAngle - Math.PI / 2, false);
        contex.lineTo(
            innerRadius * Math.cos(Math.PI / 2 - radianAngle),
            -innerRadius * Math.sin(Math.PI / 2 - radianAngle)
        );
        contex.arc(0, 0, innerRadius, radianAngle - Math.PI / 2, -Math.PI / 2, true);
        contex.closePath();
        contex.stroke();
        //开始绘制中心小圆
        contex.fillStyle = '#ffffff';
        contex.shadowColor = '#999999';
        contex.beginPath();
        contex.arc(0, 0, innerRadius, 0, 2 * Math.PI, false);
        contex.closePath();
        contex.fill();
        contex.restore();
    }

    if (angle > 180) {
        return (
            <canvas
                ref={canvasRef}
                className={className}
                width={widthNumber * appSettings.fontSize}
                height={widthNumber * appSettings.fontSize}
            >
                <div className={className} style={{ width: width, height: width, position: 'relative' }}>
                    <div
                        style={{
                            position: 'absolute',
                            width: '50%',
                            height: '100%',
                            right: 0,
                            background: color,
                            borderRadius: `0 ${width} ${width} 0`
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            width: '50%',
                            height: '100%',
                            left: 0,
                            background: bkColor,
                            borderRadius: `${width} 0 0 ${width}`
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            width: '50%',
                            height: '100%',
                            right: 0,
                            background: color,
                            borderRadius: `0 ${width} ${width} 0`,
                            transformOrigin: '0 50%',
                            transform: `rotate(${angle - 180}deg)`
                        }}
                    />
                    {innerWidth ? (
                        <div
                            style={{
                                position: 'absolute',
                                width: innerWidth,
                                height: innerWidth,
                                left: `${offset}${widthSuffix}`,
                                top: `${offset}${widthSuffix}`,
                                background: innerColor,
                                borderRadius: '50%'
                            }}
                        />
                    ) : null}
                </div>
            </canvas>
        );
    } else {
        return (
            <canvas
                ref={canvasRef}
                className={className}
                width={widthNumber * appSettings.fontSize}
                height={widthNumber * appSettings.fontSize}
            >
                <div className={className} style={{ width: width, height: width, position: 'relative' }}>
                    <div
                        style={{
                            position: 'absolute',
                            width: '50%',
                            height: '100%',
                            right: 0,
                            background: color,
                            borderRadius: `0 ${width} ${width} 0`
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            width: '50%',
                            height: '100%',
                            right: 0,
                            background: bkColor,
                            borderRadius: `0 ${width} ${width} 0`,
                            transformOrigin: '0 50%',
                            transform: `rotate(${angle}deg)`
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            width: '50%',
                            height: '100%',
                            left: 0,
                            background: bkColor,
                            borderRadius: `${width} 0 0 ${width}`
                        }}
                    />
                    {innerWidth ? (
                        <div
                            style={{
                                position: 'absolute',
                                width: innerWidth,
                                height: innerWidth,
                                left: `${offset}${widthSuffix}`,
                                top: `${offset}${widthSuffix}`,
                                background: innerColor,
                                borderRadius: '50%'
                            }}
                        />
                    ) : null}
                    {borderWidth ? (
                        <div
                            style={{
                                position: 'absolute',
                                width: width,
                                height: width,
                                left: 0,
                                top: 0,
                                borderRadius: '50%',
                                borderWidth: borderWidth,
                                borderColor: borderColor,
                                borderStyle: 'solid'
                            }}
                        ></div>
                    ) : null}
                </div>
            </canvas>
        );
    }
}

//borderWidth,
//  borderColor,
