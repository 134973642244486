import commuteIconpng from '@/assets/images/commuteIcon.png';
import { useSelector } from 'react-redux';
import lodash from 'lodash';
import { useNavigate } from 'react-router-dom';

export default function LeaveStatInfo() {
    const navigate = useNavigate();
    const classLeave = useSelector((state) => state.classLeave);
    const classInfo = useSelector((state) => state.userInfo.classInfo);
    let totalInClass = 0;
    let totalLeave = 0;
    if (classInfo && classInfo.extendInfo) {
        totalInClass = classInfo.extendInfo.cntStudent;
    }
    if (classLeave.stat && classLeave.stat.today) {
        totalLeave = classLeave.stat?.today?.length;
    }
    let precent = 0;
    if (totalInClass > 0) {
        precent = (totalInClass - totalLeave) / totalInClass;
    }
    let precentShowStr = `${lodash.floor(precent * 100)}%`;

    function onClick() {
        navigate('/leaveinfo');
    }

    return (
        <div className="w-full h-b-h px-3 py-2" onClick={onClick} alt="leaveinfo">
            <div className="w-full h-full rounded-2xl flex flex-col justify-around bg-white/60">
                <div className="flex flex-row justify-start items-center mt-4">
                    <img className="w-7 h-7 mr-6 ml-6" src={commuteIconpng}></img>
                    <p className="text-3xl">今日通勤率</p>
                </div>
                <div className="ml-6 flex flex-row justify-start items-center">
                    <div className="mr-6 h-5 w-9/12 bg-neutral-200 rounded-2xl dark:bg-neutral-600">
                        <div
                            className="h-5 rounded-2xl bg-red-600"
                            style={{ width: `${precentShowStr}` }}
                        ></div>
                    </div>
                    <p>{precentShowStr}</p>
                </div>
                <div className="ml-6 mb-4 flex flex-row justify-start items-center">
                    <p className="text-2xl mr-6">{`总人数:${totalInClass}`}</p>
                    <p className="text-2xl">{`请假:${totalLeave}`}</p>
                </div>
            </div>
        </div>
    );
}
