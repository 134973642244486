import { createSlice } from '@reduxjs/toolkit';

const BindStatus = {
    noInit:0,   //未初始化
    noBind: 1, //未绑定
    classBind: 2 //绑定到班级
};

const initialState = {
    //班牌信息
    bindStatus: BindStatus.noInit, //未初始化
    cardInfo: [],
    classMembers: [],
    faceList: [],
    bindInfo: {} //绑定信息
};

export const BanpaiInfoSlice = createSlice({
    name: 'banpaiInfo',
    initialState,
    reducers: {
        setBindStatus: (state, action) => {
            state.bindStatus = action.payload;
        },
        setBindInfo: (state, action) => {
            state.bindInfo = action.payload;
        },
        setCardBindInfo: (state, action) => {
            state.cardInfo = action.payload;
        },
        setClassMembersInfo: (state, action) => {
            state.classMembers = action.payload;
        },
        setUserFaceInfo: (state, action) => {
            state.faceList = action.payload;
        }
    }
});

export const { setBindStatus, setBindInfo, setCardBindInfo, setClassMembersInfo,setUserFaceInfo } = BanpaiInfoSlice.actions;
export { BindStatus };
export default BanpaiInfoSlice.reducer;
