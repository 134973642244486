import Header from '@/components/Header';
import Tailer from '@/components/Tailer';
import IdName from '@/components/IdName';
import IdAvatar from '@/components/IdAvatar';
import { useSelector, useDispatch } from 'react-redux';
import { NameTypes } from '@/shared/constants';
import imgMessage from '@/assets/images/message.png';
import imgVideo from '@/assets/images/video.png';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { queryChildParents, queryUserLeaveMsgStatInfo } from '@/network/schoolrequest';
import { setMineInfo } from '@/store/mine-reducer';
import { ModePropName } from '@/shared/constants';
import { clearSwipCardInfo } from '@/store/cardreaderreducer';

export default function MinePage() {
    console.log('------------- on MinePage() -------------------');
    const navigate = useNavigate();
    const navigationType = useNavigationType();
    const dispatch = useDispatch();

    const refTimer = useRef();
    const refInterval = useRef();

    const token = useSelector((state) => state.userInfo.token);
    const studentId = useSelector((state) => state.mine.studentId);
    const parentList = useSelector((state) => state.mine.parentList);

    const [countdown, setCountdown] = useState(15);

    useEffect(() => {
        const startTimer = () => {
            setCountdown(15);
            clearTimeout(refTimer.current);
            clearInterval(refInterval.current);
            refTimer.current = setTimeout(() => {
                navigate(-1);
            }, 15 * 1000);
            refInterval.current = setInterval(() => {
                setCountdown((v) => v - 1);
            }, 1000);
        };

        startTimer();
        window.addEventListener('click', startTimer);
        return () => {
            clearTimeout(refTimer.current);
            clearInterval(refInterval.current);
            window.removeEventListener('click', startTimer);
        };
    }, []);

    useEffect(() => {
        init();
        return () => {
            dispatch(clearSwipCardInfo());
        };
    }, []);

    const init = async () => {
        const unReadList = await fetchUnReadMessage();
        let newParentList;

        if (navigationType === 'POP') {
            newParentList = parentList.map((v) => {
                const unRead = unReadList.find((o) => o.userId === v.parentId);
                return {
                    ...v,
                    msgCnt: unRead?.msgCnt || 0,
                    unreadCnt: unRead?.unreadCnt || 0
                };
            });
        } else {
            newParentList = await fetchParentList();
            newParentList = newParentList.map((v) => {
                const unRead = unReadList.find((o) => o.userId === v.parentId);
                return {
                    parentId: v.parentId,
                    parentName: v.name,
                    modeProp: v.modeProp,
                    childId: v.childId,
                    msgCnt: unRead?.msgCnt || 0,
                    unreadCnt: unRead?.unreadCnt || 0
                };
            });

            newParentList.sort((a, b) => b.unreadCnt - a.unreadCnt);
        }

        dispatch(setMineInfo({ parentList: newParentList }));
    };

    const fetchParentList = async () => {
        const res = await queryChildParents({
            token,
            userId: studentId
        });

        if (res.result === 0) {
            return res.data;
        }

        return [];
    };

    const fetchUnReadMessage = async () => {
        const res = await queryUserLeaveMsgStatInfo({
            token,
            userId: studentId
        });

        if (res.result === 0) {
            return res.data;
        }
        return [];
    };

    return (
        <div className="w-full h-full flex flex-col">
            <Header />
            <div className="px-9 w-full text-3xl h-content flex justify-between">
                <div
                    className="w-[27%] h-full bg-white/60 rounded-2xl px-4 py-10 
                    flex flex-col items-center"
                >
                    <IdAvatar className="w-32 h-32 mb-9 rounded-full" nameId={studentId} />
                    <IdName className="" nameType={NameTypes.User} nameId={studentId} />
                    <div className="flex-auto"></div>
                    <div
                        className="text-white bg-[#EC3834] py-4 px-6 rounded-full"
                        onClick={() => navigate('/home')}
                    >
                        退出登录
                    </div>
                    <div className="my-4">{countdown}s</div>
                    <div className="my-4">未操作自动退出</div>
                </div>
                <div className="w-[72%] h-full flex overflow-auto">
                    {parentList.map((v) => {
                        return (
                            <div
                                key={v.parentId}
                                className="flex-none w-[32rem] mr-5 bg-white/60 rounded-2xl 
                                px-8 py-10 flex flex-col items-center"
                            >
                                <IdAvatar className="w-32 h-32 mb-9 rounded-full" nameId={v.parentId} />
                                <IdName className="" nameType={NameTypes.User} nameId={v.parentId} />
                                <div className="text-xl bg-white/80 px-8 py-2 rounded-full my-6">
                                    {ModePropName[v.modeProp]}
                                </div>
                                <div className="w-full flex justify-between pb-7 border-b border-[#c8c8c8]">
                                    {/* <div
                                        className="w-[45%] flex items-center py-2text-[#787878] 
                                        border-2 border-[#c8c8c8] rounded-xl"
                                    >
                                        <img src={imgVideo} alt="" />
                                        <span>视频通话</span>
                                    </div> */}
                                    <div
                                        className="flex w-full items-center py-2
                                        border-2 border-[#9E2CE3] rounded-xl"
                                        onClick={() => navigate(`/chat/${v.parentId}`)}
                                    >
                                        <img className="w-24" src={imgMessage} alt="" />
                                        <span>传音螺</span>
                                    </div>
                                </div>

                                <div className="mt-7 w-full">
                                    <div className="text-2xl mt-4 overflow-hidden whitespace-nowrap text-ellipsis">
                                        <span>消息总数：{v.msgCnt}</span>
                                        <span className="ml-8">未读：{v.unreadCnt}</span>
                                    </div>
                                </div>
                                <div className="flex-auto"></div>
                                <div
                                    className="mb-4 text-3xl text-white bg-[#0185F1] py-4 px-12 rounded-full"
                                    onClick={() => navigate(`/chat/${v.parentId}`)}
                                >
                                    进入详情
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Tailer />
        </div>
    );
}
