const config = {
  banpaiSvr: {
    domain: 'https://banpai.app.aohuacloud.com',
    uploadDomain: 'https://upload.app.aohuacloud.com'
  },
  resInfo: {
    webUrl: 'https://aohuaresource.oss-cn-beijing.aliyuncs.com/hhweb/cloudshow',
    resUrl: 'https://aohuaresource.oss-cn-beijing.aliyuncs.com'
  }
};

export default config;
