import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    ids: {},
    query: [],
    avatar: {},
    querying:[]
};

export const IdNameCacheSlice = createSlice({
    name: 'idNameCache',
    initialState,
    reducers: {
        setIdNameInfo: (state, action) => {
            const { type, id, value } = action.payload;
            if (!state.ids[`${type}`]) {
                state.ids[`${type}`] = {};
            }
            state.ids[`${type}`][`${id}`] = value;
            for(let i = 0; i<state.querying.length; i++){
                if ((state.querying[i].id === id) && (state.querying[i].type === type)){
                    state.querying.splice(i,1);
                    break;
                }
            }
        },
        setQueryInfo: (state, action) => {
            let {type,id} = action.payload;
            for(let i = 0; i<state.query.length; i++){
                if ((state.query[i].id === id) && (state.query[i].type === type)){
                    return;
                }
            }
            for(let i = 0; i<state.querying.length; i++){
                if ((state.querying[i].id === id) && (state.querying[i].type === type)){
                    return;
                }
            }
            state.query.push(action.payload);
        },
        clearQueryInfo: (state, action) => {
            for(let i = 0; i<state.query.length; i++){
                state.querying.push(state.query[i]);
            }
            state.query = [];
        },
        setAvatarInfo: (state, action) => {
            const { id, avatar } = action.payload;
            state.avatar[`${id}`] = avatar;
        }
    }
});

export const { setIdNameInfo, setQueryInfo, clearQueryInfo, setAvatarInfo } = IdNameCacheSlice.actions;
export default IdNameCacheSlice.reducer;
