import { combineReducers, configureStore } from '@reduxjs/toolkit';
import appSettingsReducer from './appsettingsreducer';
import banpaiReducer from './banpaireducer';
import userinfoReducer from './userinforeducer';
import keepaliveReducer from './keepalivereducer';
import deviceReducer from './devicereducer';

import redFlowerReducer from './redflowerreducer';
import redFlagReducer from './redflagreducer';
import classMomentReducer from './classmomentreducer';
import classLeaveReducer from './classleavereducer';
import classMessageReducer from './class-message-reducer';
import classScheduleReducer from './classschedulereducer';
import idNameCacheReducer from './idnamecachereducer';
import weatherReducer from './weatherinforeducer';
import attendancereducer from './attendancereducer';
import mineReducer from './mine-reducer';
import cardReaderReducer from './cardreaderreducer';

const store = configureStore({
    reducer: {
        // home: combineReducers({
        //     bindInfo: bindInfoReducer,
        //     userInfo: userInfoReducer
        // })
        appSettingsInfo: appSettingsReducer,
        banpaiInfo: banpaiReducer,
        classLeave: classLeaveReducer,
        classMessage: classMessageReducer,
        classMoment: classMomentReducer,
        classSchedule: classScheduleReducer,
        deviceInfo: deviceReducer,
        idNameCache: idNameCacheReducer,
        keepalive: keepaliveReducer,
        mine: mineReducer,
        redFlower: redFlowerReducer,
        redFlag: redFlagReducer,
        userInfo: userinfoReducer,
        weatherInfo: weatherReducer,
        cardReader: cardReaderReducer,
        attendance: attendancereducer
    }
});
export default store;
