import config from '@/shared/appconfig.js';
import { post } from '@/apis/request.js';

export async function uploadFile({
  token,
  appId,
  subId,
  showName,
  fileMd5,
  fileLength,
  start,
  length,
  content
} = {}) {
  const url = `${config.banpaiSvr.uploadDomain}/app/upload/UploadFile`;
  const ret = await post(url, {
    token,
    appId,
    subId,
    showName,
    fileMd5,
    fileLength,
    start,
    length,
    content
  });
  return ret;
}
