import Header from '@/components/Header';
import { useParams } from 'react-router-dom';
import imgBack from '@/assets/images/back.png';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState, useRef, useCallback } from 'react';
import imgTagClass from '@/assets/images/tag-class.png';
import imgTagGrade from '@/assets/images/tag-grade.png';
import imgTagSchool from '@/assets/images/tag-school.png';
import imgThumb from '@/assets/images/thumb-hollow.png';
import IdName from '@/components/IdName';
import IdAvatar from '@/components/IdAvatar';
import { queryMomentGet, queryCommentList, queryFavoriteUserList } from '@/network/classapprequest';
import { NameTypes } from '@/shared/constants';
import dayjs from 'dayjs';
import config from '@/shared/appconfig';
import utils from '@/shared/utils';
import _ from 'lodash';
import { MomentLevel } from '@/shared/constants';

const PAGE_SIZE = 10;
const USER_PAGE_SIZE = 40;

export default function MomentDetail() {
    const navigate = useNavigate();
    let { momentId } = useParams();
    momentId = parseInt(momentId, 10);

    const token = useSelector((state) => state.userInfo.userInfo.token);

    const [detail, setDetail] = useState({});
    const [commentList, setCommentList] = useState([]);
    const [commentPageIndex, setCommentPageIndex] = useState(0);
    const [hasMoreComment, setHasMoreComment] = useState(false);

    const [favoriteUserList, setFavoriteUserList] = useState([]);
    const [favoriteUserPageIndex, setFavoriteUserPageIndex] = useState(0);
    const [hasMoreFavoriteUser, setHasMoreFavoriteUser] = useState(false);

    const [commentScrollTop, setCommentScrollTop] = useState(0);
    const [favoriteUserScrollTop, setFavoriteUserScrollTop] = useState(0);

    const refCommentScroll = useRef();
    const refFavoriteScroll = useRef();

    useEffect(() => {
        fetchDetail();
        fetchCommentList();
        fetchFavoriteUserList();
    }, []);

    const fetchDetail = async () => {
        const res = await queryMomentGet(token, momentId);
        if (res.result === 0) {
            setDetail(res.data);
        }
    };

    const fetchCommentList = async (loadMore = false) => {
        const res = await queryCommentList({
            momentId: momentId,
            pageIndex: loadMore ? commentPageIndex + 1 : 0,
            pageSize: PAGE_SIZE
        });

        if (res.result === 0) {
            setCommentPageIndex(loadMore ? commentPageIndex + 1 : 0);
            setHasMoreComment(res.data.length === PAGE_SIZE);
            setCommentList(loadMore ? commentList.concat(res.data) : res.data);
        }
    };

    const fetchFavoriteUserList = async (loadMore = false) => {
        const res = await queryFavoriteUserList({
            momentId: momentId,
            pageIndex: loadMore ? favoriteUserPageIndex + 1 : 0,
            pageSize: USER_PAGE_SIZE
        });

        if (res.result === 0) {
            setFavoriteUserPageIndex(loadMore ? favoriteUserPageIndex + 1 : 0);
            setHasMoreFavoriteUser(res.data.length === USER_PAGE_SIZE);
            setFavoriteUserList(loadMore ? favoriteUserList.concat(res.data) : res.data);
        }
    };

    const handleCommentScroll = useCallback(
        _.throttle(async (e) => {
            e.stopPropagation();
            const { clientHeight, scrollHeight, scrollTop } = e.target;
            setCommentScrollTop(scrollTop);
            if (scrollTop + clientHeight >= scrollHeight && hasMoreComment) {
                await fetchCommentList(true);
            }
        }, 150),
        [hasMoreComment, fetchCommentList]
    );

    const handleFavoriteUserScroll = useCallback(
        _.throttle(async (e) => {
            e.stopPropagation();
            const { clientHeight, scrollHeight, scrollTop } = e.target;
            setFavoriteUserScrollTop(scrollTop);
            if (scrollTop + clientHeight >= scrollHeight && hasMoreComment) {
                await fetchFavoriteUserList(true);
            }
        }, 150),
        [hasMoreComment, fetchFavoriteUserList]
    );

    return (
        <div className="w-full h-full flex flex-col">
            <Header />

            <div className="px-9 w-full h-content flex justify-between">
                <div className="w-[45%] h-full bg-white/60 rounded-2xl px-4 py-10 overflow-auto">
                    <p className="text-3xl mb-7 flex">
                        {detail.content || ''}
                        {(detail.topics || []).map((v) => {
                            return (
                                <IdName
                                    className="text-[#467BF6]"
                                    nameType={NameTypes.Topic}
                                    nameId={parseInt(v, 10)}
                                ></IdName>
                            );
                        })}
                    </p>

                    <div className="flex mb-7">
                        <img className="w-9 h-9 object-scale-down" src={imgTagClass} alt="" />
                        {detail.level > MomentLevel.Class && (
                            <img className="w-9 h-9 object-scale-down" src={imgTagGrade} alt="" />
                        )}
                        {detail.level === MomentLevel.School && (
                            <img className="w-9 h-9 object-scale-down" src={imgTagSchool} alt="" />
                        )}
                    </div>

                    <div className="flex items-center mb-7">
                        <IdAvatar className="w-20 h-20 rounded-full mr-4" nameId={detail.userId} />
                        <IdName
                            className="text-2xl flex-auto"
                            nameType={NameTypes.User}
                            nameId={detail.userId}
                        />
                        <span className="mr-8 text-xl text-[#787878]">
                            {dayjs(detail.createTime).format('YYYY.MM.DD')}
                        </span>
                        <span className="text-xl text-[#787878]">{detail.visitCnt}阅读数</span>
                    </div>

                    <div>
                        {(detail.res || []).map((v) => {
                            return <img className="w-full" src={config.resInfo.resUrl + '/' + v} alt="" />;
                        })}
                    </div>
                </div>

                <div
                    ref={refCommentScroll}
                    className="w-[28.7%] h-full bg-white/60 rounded-2xl px-4 py-10 overflow-auto"
                    onScroll={handleCommentScroll}
                >
                    <div className="text-2xl text-[#444444] mb-7">评论 {detail.commentCnt || 0}</div>

                    {commentList.map((v) => {
                        return (
                            <div key={v.id} className="flex text-[#444] mb-6">
                                <IdAvatar className="w-20 h-20 rounded-full mr-4" nameId={v.userId} />
                                <div className="flex-auto">
                                    <div className="flex items-center mb-2">
                                        <IdName
                                            className="text-2xl flex-auto"
                                            nameType={NameTypes.User}
                                            nameId={v.userId}
                                        />
                                        <img src={imgThumb} alt="" />
                                        <span className="ml-4">{v.favoriteCnt}</span>
                                    </div>
                                    <div className="text-2xl mb-2">{v.comment}</div>
                                    <div className="text-#787878]">{utils.formatDuration(v.createTime)}</div>
                                </div>
                            </div>
                        );
                    })}

                    {commentList.length > 0 && commentScrollTop > 0 && !hasMoreComment && (
                        <div className="text-center py-8 text-2xl text-gray-500">到底了</div>
                    )}
                </div>

                <div
                    ref={refFavoriteScroll}
                    className="w-[23.5%] h-full bg-white/60 rounded-2xl py-10 overflow-auto"
                    onScroll={handleFavoriteUserScroll}
                >
                    <div className="px-4  text-2xl text-[#444444] mb-7">点赞 {detail.favoriteCnt || 0}</div>

                    <div className="flex flex-wrap">
                        {favoriteUserList.map((v) => {
                            return (
                                <div
                                    key={v.id}
                                    className="w-1/4 mb-6 flex flex-col items-center overflow-hidden"
                                >
                                    <IdAvatar className="w-16 h-16 rounded-full" nameId={v.userId} />
                                    <IdName
                                        className="text-2xl flex-auto w-[80%] overflow-hidden"
                                        nameType={NameTypes.User}
                                        nameId={v.userId}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    {favoriteUserList.length > 0 && favoriteUserScrollTop > 0 && !hasMoreFavoriteUser && (
                        <div className="text-center py-8 text-2xl text-gray-500">到底了</div>
                    )}
                </div>
            </div>

            <div className="w-full flex-none h-tailer flex justify-center">
                <div className="relative w-32 h-32" onClick={() => navigate(-1)}>
                    <img className="w-32 h-32" src={imgBack} alt="" />
                    <span
                        className="absolute translate-x-[-50%] left-[50%] bottom-8
                      text-[#FF4245] text-2xl"
                    >
                        返回
                    </span>
                </div>
            </div>
        </div>
    );
}
