import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    stat: {},
    hotMoment: [],
    curTabIndex: 0,
    momentList: [],
    hasMore: false,
    pageIndex: 0,
    pageSize: 10
};

export const ClassMomentSlice = createSlice({
    name: 'classMoment',
    initialState,
    reducers: {
        setHotMoment: (state, action) => {
            state.hotMoment = action.payload;
        },
        setClassMomentInfo: (state, action) => {
            Object.assign(state, action.payload);
        },
        setClassMomentMonthStatInfo: (state, action) => {
            let { cntInClass, cntInGrade, cntInSchool } = action.payload;
            state.stat.month = {
                cntInClass,
                cntInGrade,
                cntInSchool
            };
        }
    }
});

export const { setHotMoment, setClassMomentInfo, setClassMomentMonthStatInfo } = ClassMomentSlice.actions;
export default ClassMomentSlice.reducer;
