import ColorTabs from '@/components/colortabs';
import RedFlagIcon from '@/assets/images/redFlagicon.png';
import IdName from '@/components/IdName';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { queryRedFlagRankListInRange } from '@/network/classapprequest';
import { ShowWaiting, HideWaiting } from '@/components/MsgBox';
import { setRedFlagWeekStat, setRedFlagMonthStat, setRedFlagTermStat, setRedFlagYearStat } from '@/store/redflagreducer';

export default function RankInfo() {
    let rankTabs = {
        bkColor: '#23B31200',
        textColor: '#999999',
        bkFocusColor: '#1DB79F',
        textFocusColor: '#ffffff',
        fontSize: '24px',
        margin: '50px',
        border: {
            width: '1px',
            style: 'dashed',
            color: '#999999',
            focusColor: '#999999'
        },
        tabs: [
            {
                content: '年级榜'
            },
            {
                content: '全校榜'
            }
        ]
    };
    let rangeTabs = {
        bkColor: '#23B31200',
        textColor: '#999999',
        bkFocusColor: '#FFB31200',
        textFocusColor: '#EC3834',
        fontSize: '28px',
        margin: '20px',
        indicate: {
            width: '30px',
            height: '3px',
            color: '#EC3834',
            borderRadius: '2px',
            marginTop: '10px'
        },
        tabs: [
            {
                content: '周榜'
            },
            {
                content: '月榜'
            },
            {
                content: '期榜'
            },
            {
                content: '年榜'
            }
        ]
    };

    const redFlagInfo = useSelector((state) => state.redFlag);
    const userInfo = useSelector((state) => state.userInfo);
    const dispatch = useDispatch();
    const [selectInfo, setSelectInfo] = useState({
        rank: 0,
        range: 0
    });
    useEffect(() => {
        onSelectChanged();
    }, [selectInfo, userInfo.classInfo]);

    function onRankTabClicked(index) {
        setSelectInfo({
            ...selectInfo,
            rank: index
        });
    }

    function onRangeTabClicked(index) {
        setSelectInfo({
            ...selectInfo,
            range: index
        });
    }
    function getRedFlagList() {
        switch (selectInfo.rank) {
            case 0:
                switch (selectInfo.range) {
                    case 0:
                        return redFlagInfo?.grade?.week;
                    case 1:
                        return redFlagInfo?.grade?.month;
                    case 2:
                        return redFlagInfo?.grade?.term;
                    default:
                        return redFlagInfo?.grade?.year;
                }
                break;
            default:
                switch (selectInfo.range) {
                    case 0:
                        return redFlagInfo?.school?.week;
                    case 1:
                        return redFlagInfo?.school?.month;
                    case 2:
                        return redFlagInfo?.school?.term;
                    default:
                        return redFlagInfo?.school?.year;
                }
                break;
        }
    }

    function setRedFlagListData(list) {
        switch (selectInfo.rank) {
            case 0:
                switch (selectInfo.range) {
                    case 0:
                        dispatch(setRedFlagWeekStat({ range: 'grade', list }));
                        break;
                    case 1:
                        dispatch(setRedFlagMonthStat({ range: 'grade', list }));
                        break;
                    case 2:
                        dispatch(setRedFlagTermStat({ range: 'grade', list }));
                        break;
                    default:
                        dispatch(setRedFlagYearStat({ range: 'grade', list }));
                        break;
                }
                break;
            default:
                switch (selectInfo.range) {
                    case 0:
                        dispatch(setRedFlagWeekStat({ range: 'school', list }));
                        break;
                    case 1:
                        dispatch(setRedFlagMonthStat({ range: 'school', list }));
                        break;
                    case 2:
                        dispatch(setRedFlagTermStat({ range: 'school', list }));
                        break;
                    default:
                        dispatch(setRedFlagYearStat({ range: 'school', list }));
                        break;
                }
                break;
        }
    }

    //(token, schoolId, gradeId, classId, periodType, count) {
    //periodType 0=不限制时间 1=本周 2=本月 3=本学期 4=本学年
    async function onSelectChanged() {
        let showList = getRedFlagList();
        let period = 1;
        if (selectInfo.range >= 0 && selectInfo.range < 4) {
            period = selectInfo.range + 1;
        }
        if (!showList) {
            ShowWaiting('正在请求红旗统计信息。。。');
            let res = await queryRedFlagRankListInRange(userInfo.token, userInfo.classInfo.schoolId, selectInfo.rank < 1 ? userInfo.classInfo.gradeId : 0, 0, period, 100);
            HideWaiting();
            if (res && res.result === 0) {
                setRedFlagListData(res.data);
            } else {
                console.log(`----queryRedFlagRankListInRange return code= ${res.result} msg=${res.msg}`);
            }
        }
    }

    function ClassInfoComponent({ item, index }) {
        if (item.classId === 0) {
            return <div className="w-i-w mt-4 h-28 " />;
        } else {
            return (
                <div className="w-i-w mt-4 h-28 border-2 border-dashed bg-white border-c-c flex flex-row justify-between items-center">
                    <div className="flex flex-row justify-start items-center">
                        <div className="w-9 h-9 rounded-full border  border-red-600 border-2 mx-4 flex flex-row justify-center items-center">
                            <p className="text-xl">{index}</p>
                        </div>
                        <IdName nameId={item.classId} nameType={4} />
                    </div>
                    <div className="flex flex-row justify-end items-center">
                        <p>{item.count}</p>
                        <img className="w-6 h-8 mx-4" src={RedFlagIcon} />
                    </div>
                </div>
            );
        }
    }

    function getRankListComponent() {
        let itemList = [];
        let srcList = getRedFlagList();
        if (srcList) {
            for (let i = 0; i < srcList.length; i++) {
                itemList.push(srcList[i]);
            }
            if (itemList.length % 3 === 2) {
                //补上缺省的，当一行只有2个元素时，对齐方式不对，给他加一个空元素
                itemList.push({
                    schoolId: 0,
                    gradeId: 0,
                    classId: 0,
                    count: 0
                });
            }
        }
        return (
            <div className="w-full flex flex-row flex-wrap justify-between mb-4">
                {itemList.map((item, index) => {
                    return <ClassInfoComponent item={item} index={index + 1} key={`cls_${item.classId}`} />;
                })}
            </div>
        );
    }

    return (
        <div className="w-full h-full">
            <div className="w-full h-14 mt-11">
                <ColorTabs tabInfo={rankTabs} index={0} onTabClicked={onRankTabClicked} />
            </div>
            <div className="w-full h-16 mt-8">
                <ColorTabs tabInfo={rangeTabs} index={0} onTabClicked={onRangeTabClicked} />
            </div>
            <div className="w-5/6 mx-auto my-6" style={{ height: '2px', borderColor: '#999999', borderWidth: '2px', borderStyle: 'dashed', borderColor: '#999999' }} />
            <div className="w-5/6 h-i-h overflow-y-auto mx-auto px-2">
                <div className="w-full flex flex-row flex-wrap justify-between mb-4">{getRankListComponent()}</div>
            </div>
        </div>
    );
}
