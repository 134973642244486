import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    stat: {},
    detail: {}
};

export const ClassLeaveSlice = createSlice({
    name: 'classLeave',
    initialState,
    reducers: {
        setLeaveTodayStat: (state, action) => {
            state.stat.today = action.payload;
        },
        setLeaveWeekStat: (state, action) => {
            state.stat.week = action.payload;
        },
        setLeaveMonthStat: (state, action) => {
            state.stat.month = action.payload;
        },
        setLeaveTodayDetail: (state, action) => {
            state.detail.today = action.payload;
        },
        setLeaveWeekDetail: (state, action) => {
            state.detail.week = action.payload;
        },
        setLeaveMonthDetail: (state, action) => {
            state.detail.month = action.payload;
        }
    }
});

export const { setLeaveTodayStat, setLeaveWeekStat, setLeaveMonthStat, setLeaveTodayDetail, setLeaveWeekDetail, setLeaveMonthDetail } = ClassLeaveSlice.actions;
export default ClassLeaveSlice.reducer;
