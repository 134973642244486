import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    //uuid: '7d004bbd-243d-4a03-bd49-79563c2ced2e',
    requestCnt: 0, //请求设备信息次数
    uuid: '',
    info: {} //设备信息
};

export const DeviceInfoSlice = createSlice({
    name: 'deviceInfo',
    initialState,
    reducers: {
        increaseDeviceIdRequestCnt: (state, action) => {
            state.requestCnt++;
        },
        setDeviceInfo: (state, action) => {
            state.info = action.payload;
        },
        setDeviceUUID: (state, action) => {
            state.uuid = action.payload;
        }
    }
});

export const { setDeviceInfo, setDeviceUUID, increaseDeviceIdRequestCnt } = DeviceInfoSlice.actions;
export default DeviceInfoSlice.reducer;
