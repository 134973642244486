import Header from '@/components/Header';
import IdName from '@/components/IdName';
import IdAvatar from '@/components/IdAvatar';
import { useSelector, useDispatch } from 'react-redux';
import { APP_ID, NameTypes, MessageType } from '@/shared/constants';
import imgBack from '@/assets/images/back.png';
import imgMicroPhone from '@/assets/images/microphone.png';
import { useParams, useNavigate } from 'react-router-dom';
import { queryLeaveMsgListByUserId, sendLeaveMsg, setLeaveMsgReadStatus } from '@/network/schoolrequest';
import { uploadFile } from '@/network/uplad-request';
import { useState, useEffect, useRef, useCallback } from 'react';
import usePrevious from '@/hooks/usePrevious';
import cx from 'classnames';
import Wave from '@/components/wave';
// import Recorder from './recorder';
import NativeRecorder from './native-recorder';
import dayjs from 'dayjs';
import _ from 'lodash';
import crypto from 'crypto-js';
import config from '@/shared/appconfig';
import AudioPlayer from './audio-player';
import { clearSwipCardInfo } from '@/store/cardreaderreducer';

const PAGE_SIZE = 10;

export default function Chat() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { parentId } = useParams();
    parentId = parseInt(parentId, 10);

    const token = useSelector((state) => state.userInfo.token);
    const studentId = useSelector((state) => state.mine.studentId);

    const refTimer = useRef();
    const refInterval = useRef();

    const [messageList, setMessageList] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const prevMessageList = usePrevious(messageList);

    const [inputMode, setInputMode] = useState(0);
    const [isTouchStart, setIsTouchStart] = useState(false);
    const [canCancel, setCanCancel] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [countdown, setCountdown] = useState(15);

    const refInput = useRef();
    const refScroll = useRef();
    const refStartPoint = useRef();
    const refRecorder = useRef();
    const refScrollHeight = useRef();

    useEffect(() => {
        const startTimer = () => {
            setCountdown(15);
            clearTimeout(refTimer.current);
            clearInterval(refInterval.current);
            refTimer.current = setTimeout(() => {
                navigate(-1);
            }, 15 * 1000);
            refInterval.current = setInterval(() => {
                setCountdown((v) => v - 1);
            }, 1000);
        };

        startTimer();
        window.addEventListener('click', startTimer);
        return () => {
            clearTimeout(refTimer.current);
            clearInterval(refInterval.current);
            window.removeEventListener('click', startTimer);
        };
    }, []);

    useEffect(() => {
        fetchMessage();
        // refRecorder.current = new Recorder({ onStop: handleRecordStop });
        refRecorder.current = new NativeRecorder({ onStop: handleNativeRecordStop });

        return () => {
            dispatch(clearSwipCardInfo());
        };
    }, []);

    useEffect(() => {
        const setRead = async () => {
            const last = _.last(messageList);
            if (last) {
                await setLeaveMsgReadStatus({
                    token,
                    userId: studentId,
                    sendUserId: last.sendId,
                    msgId: last.id
                });
            }
        };

        if (prevMessageList?.length === 0 && messageList.length > 0) {
            setRead();
        }

        if (messageList.length > 0 && pageIndex > 0) {
            refScroll.current.scrollTop = refScroll.current.scrollHeight - refScrollHeight.current;
        }

        if (
            (prevMessageList?.length === 0 && messageList.length > 0) ||
            _.last(messageList)?.id !== _.last(prevMessageList || [])?.id
        ) {
            refScroll.current.scrollTop = refScroll.current.scrollHeight - refScroll.current.clientHeight;
        }
    }, [prevMessageList, messageList]);

    const fetchMessage = async (loadMore = false) => {
        const res = await queryLeaveMsgListByUserId({
            token,
            userId: studentId,
            peerUserId: parentId,
            pageIndex: loadMore ? pageIndex + 1 : 0,
            pageSize: PAGE_SIZE
        });

        if (res.result === 0) {
            const list = res.data.reverse();
            setHasMore(res.data.length === PAGE_SIZE);
            setPageIndex(loadMore ? pageIndex + 1 : 0);
            setMessageList(loadMore ? [...list, ...messageList] : list);
        }
    };

    const handleNativeRecordStop = async ({ fileBase64, length, duration }) => {
        const fileMd5 = crypto.MD5(fileBase64).toString();
        let res = await uploadFile({
            token,
            appId: APP_ID,
            subId: '0',
            showName: fileMd5 + '.aac',
            fileMd5: fileMd5,
            fileLength: length,
            start: 0,
            content: fileBase64
        });

        if (res.result === 0) {
            const url = res.url;

            res = await sendLeaveMsg({
                token,
                userId: studentId,
                recvUserId: parentId,
                msgType: MessageType.Audio,
                msgDuration: duration / 1000,
                msgContent: url
            });

            if (res.result === 0) {
                fetchMessage();
            }
        }
    };

    const handleRecordStop = async (state, { blob, duration }) => {
        setIsTouchStart(false);
        setCanCancel(false);

        const fileMd5 = await new Promise((resolve) => {
            const fr = new FileReader();
            fr.readAsArrayBuffer(blob);
            fr.onload = (e) => {
                const wordArray = crypto.lib.WordArray.create(fr.result);
                const hash = crypto.MD5(wordArray).toString();
                resolve(hash);
            };
        });

        const fileBase64 = await new Promise((resolve) => {
            const fr = new FileReader();
            fr.readAsDataURL(blob);
            fr.onload = (e) => {
                resolve(e.target.result);
            };
        });

        let res = await uploadFile({
            token,
            appId: APP_ID,
            subId: '0',
            showName: fileMd5 + '.ogg',
            fileMd5: fileMd5,
            fileLength: blob.size,
            start: 0,
            content: fileBase64
        });

        if (res.result === 0) {
            const url = res.url;

            res = await sendLeaveMsg({
                token,
                userId: studentId,
                recvUserId: parentId,
                msgType: MessageType.Audio,
                msgDuration: duration / 1000,
                msgContent: url
            });

            if (res.result === 0) {
                fetchMessage();
            }
        }
    };

    const handleScroll = useCallback(
        _.throttle(async (e) => {
            e.stopPropagation();
            const { scrollTop, scrollHeight } = e.target;
            refScrollHeight.current = scrollHeight;

            if (scrollTop === 0 && hasMore) {
                await fetchMessage(true);
            }
        }, 150),
        [hasMore, fetchMessage]
    );

    const handleTouchStart = (e) => {
        console.log('touch start', e);
        refStartPoint.current = e.touches[0];

        setIsTouchStart(true);
        refRecorder.current.start();
    };

    const handleTouchEnd = (e) => {
        console.log('touch end');
        setIsTouchStart(false);
        setCanCancel(false);
        refRecorder.current.stop();
    };

    const handleTouchMove = (e) => {
        console.log('touch move', e);
        const point = e.touches[0];
        if (Math.abs(point.clientY - refStartPoint.current.clientY) > 100) {
            setCanCancel(true);
        } else {
            setCanCancel(false);
        }
    };

    const handleSend = async () => {
        const text = refInput.current.value;
        if (!text.trim()) {
            return;
        }

        const res = await sendLeaveMsg({
            token,
            userId: studentId,
            recvUserId: parentId,
            msgType: MessageType.Text,
            msgDuration: 0,
            msgContent: text
        });

        if (res.result === 0) {
            refInput.current.value = '';
            fetchMessage();
        }
    };

    return (
        <div className="w-full h-full flex flex-col">
            <Header />
            <div className="px-9 w-full text-3xl h-content flex justify-between">
                <div
                    className="w-[27%] h-full bg-white/60 rounded-2xl px-4 py-10 
                    flex flex-col items-center"
                >
                    <IdAvatar className="w-32 h-32 mb-9" nameId={studentId} />
                    <IdName className="" nameType={NameTypes.User} nameId={studentId} />
                    <div className="flex-auto"></div>
                    <div
                        className="text-white bg-[#EC3834] py-4 px-6 rounded-full"
                        onClick={() => navigate('/home')}
                    >
                        退出登录
                    </div>
                    <div className="my-4">{countdown}s</div>
                    <div className="my-4">未操作自动退出</div>
                </div>
                <div
                    className="relative w-[72%] h-full bg-white/60 rounded-2xl 
                    px-4 py-10 flex"
                >
                    <div
                        ref={refScroll}
                        className="absolute top-[20px] bottom-[120px] left-[30px] right-[30px] 
                        overflow-auto"
                        onScroll={handleScroll}
                    >
                        {messageList.length > 0 &&
                            !hasMore &&
                            (refScroll.current?.scrollHeight || 0) >
                                (refScroll.current?.clientHeight || 0) && (
                                <div className="text-center py-8 text-2xl text-gray-500">已全部加载</div>
                            )}
                        <div>
                            {messageList.map((v) => {
                                if (v.sendId !== studentId) {
                                    return (
                                        <div key={v.id} className="flex text-[#444] mb-8">
                                            <IdAvatar
                                                className="w-24 h-24 mr-5 rounded-full"
                                                nameId={v.sendId}
                                            />
                                            <div className="max-w-[55%]">
                                                <div className="flex items-end mb-5">
                                                    <IdName
                                                        className="text-3xl"
                                                        nameType={NameTypes.User}
                                                        nameId={v.sendId}
                                                    />
                                                    <div className="text-xl">
                                                        {dayjs(v.sendTime * 1000).format(
                                                            'YYYY-MM-DD HH:mm:ss'
                                                        )}
                                                    </div>
                                                </div>
                                                {v.msgType === MessageType.Text && (
                                                    <div className="text-2xl bg-white/80 p-4 rounded-xl border border-[#c8c8c8]">
                                                        {v.content}
                                                    </div>
                                                )}
                                                {v.msgType === MessageType.Audio && (
                                                    <AudioPlayer
                                                        position="left"
                                                        duration={v.duration}
                                                        src={`${config.resInfo.resUrl}/${v.content}`}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                }

                                return (
                                    <div key={v.id} className="flex text-[#444] mb-8 justify-end">
                                        <div className="max-w-[55%] flex flex-col items-end">
                                            <div className="flex justify-end items-end mb-5">
                                                <div className="text-xl mr-4">
                                                    {dayjs(v.sendTime * 1000).format('YYYY-MM-DD HH:mm:ss')}
                                                </div>
                                                <IdName
                                                    className="text-3xl"
                                                    nameType={NameTypes.User}
                                                    nameId={v.sendId}
                                                />
                                            </div>
                                            {v.msgType === MessageType.Text && (
                                                <div className="text-2xl bg-white/80 p-4 rounded-xl border border-[#c8c8c8]">
                                                    {v.content}
                                                </div>
                                            )}
                                            {v.msgType === MessageType.Audio && (
                                                <AudioPlayer
                                                    position="right"
                                                    duration={v.duration}
                                                    src={`${config.resInfo.resUrl}/${v.content}`}
                                                />
                                            )}
                                        </div>
                                        <IdAvatar className="w-24 h-24 ml-5 rounded-full" nameId={v.sendId} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div
                        className={cx('py-[10px] bottom-0', {
                            absolute: !isTyping,
                            // 'h-[7.5rem]': !isTyping,
                            'left-[30px]': !isTyping,
                            'right-[30px]': !isTyping,
                            fixed: isTyping,
                            'top-[10%]': isTyping,
                            'left-0': isTyping,
                            'right-0': isTyping,
                            'bg-white': isTyping
                        })}
                    >
                        <div className="flex h-[7.5rem] rounded-xl bg-white p-2.5">
                            <div
                                className="flex-none h-full w-[5rem] flex items-center justify-center 
                                    border-r border-[#c8c8c8]"
                                onClick={() => setInputMode(inputMode === 0 ? 1 : 0)}
                            >
                                <img className="w-[50%]" src={imgMicroPhone} alt="" />
                            </div>
                            {inputMode === 0 ? (
                                <input
                                    ref={refInput}
                                    className="flex-auto mx-4 focus:outline-none"
                                    placeholder="请在此输入留言内容，60个字以内"
                                    maxLength={60}
                                    onFocus={() => setIsTyping(true)}
                                    onBlur={() =>
                                        setTimeout(() => {
                                            setIsTyping(false);
                                        }, 0)
                                    }
                                />
                            ) : (
                                <div
                                    className="relative flex-auto flex items-center justify-center"
                                    onTouchStart={handleTouchStart}
                                    onTouchEnd={handleTouchEnd}
                                    onTouchMove={handleTouchMove}
                                >
                                    <span>{isTouchStart ? '说话中...' : '按住说话'}</span>
                                    {isTouchStart && (
                                        <div
                                            className={cx(
                                                'absolute flex flex-col justify-center items-center px-12',
                                                'text-white h-[100px] top-[-240px] rounded-xl',
                                                {
                                                    'bg-green-600': !canCancel,
                                                    'bg-red-500': canCancel
                                                }
                                            )}
                                        >
                                            <div className="w-full h-[30px]">
                                                <Wave />
                                            </div>
                                            <div className="text-md pt-2">
                                                {canCancel ? '松开取消录音' : '松开结束录音'}
                                            </div>
                                            <div
                                                className={cx(
                                                    'absolute left-[50%] translate-x-[-50%] bottom-[-38px]',
                                                    'w-0 h-0 border-x-transparent border-b-transparent border-[20px]',
                                                    {
                                                        'border-green-600': !canCancel,
                                                        'border-red-500': canCancel
                                                    }
                                                )}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            <div
                                className="h-full bg-[#00A790] rounded-xl flex items-center
                               text-white px-8"
                                onClick={handleSend}
                            >
                                发送
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full flex-none h-tailer flex justify-center">
                <div className="relative w-32 h-32" onClick={() => navigate(-1)}>
                    <img className="w-32 h-32" src={imgBack} alt="" />
                    <span
                        className="absolute translate-x-[-50%] left-[50%] bottom-8 
                        text-[#FF4245] text-2xl"
                    >
                        返回
                    </span>
                </div>
            </div>
        </div>
    );
}
