import ColorTabs from '@/components/colortabs';
import { useEffect, useCallback, useRef } from 'react';
import Masonry from 'react-responsive-masonry';
import { queryMomentList } from '@/network/classapprequest';
import { useSelector, useDispatch } from 'react-redux';
import { setClassMomentInfo } from '@/store/classmomentreducer';
import IdName from '@/components/IdName';
import IdAvatar from '@/components/IdAvatar';
import { NameTypes } from '@/shared/constants';
import imgTagClass from '@/assets/images/tag-class.png';
import imgTagGrade from '@/assets/images/tag-grade.png';
import imgTagSchool from '@/assets/images/tag-school.png';
import imgComment from '@/assets/images/comment.png';
import imgThumbHollow from '@/assets/images/thumb-hollow.png';
import { MomentLevel } from '@/shared/constants';
import config from '@/shared/appconfig';
import { ShowWaiting, HideWaiting } from '@/components/MsgBox';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

export default function RankInfo() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const curTabIndex = useSelector((state) => state.classMoment.curTabIndex);
    const classInfo = useSelector((state) => state.userInfo.classInfo);
    const token = useSelector((state) => state.userInfo.token);
    const pageIndex = useSelector((state) => state.classMoment.pageIndex);
    const pageSize = useSelector((state) => state.classMoment.pageSize);
    const momentList = useSelector((state) => state.classMoment.momentList);
    const hasMore = useSelector((state) => state.classMoment.hasMore);

    const refScroll = useRef();

    const rankTabs = {
        bkColor: '#23B31200',
        textColor: '#999999',
        bkFocusColor: '#1DB79F',
        textFocusColor: '#ffffff',
        fontSize: '24px',
        margin: '50px',
        border: {
            width: '1px',
            style: 'dashed',
            color: '#999999',
            focusColor: '#999999'
        },
        tabs: [
            {
                content: '班级风采'
            },
            {
                content: '年级风采'
            },
            {
                content: '校级风采'
            }
        ]
    };

    const fetchMomentList = async (loadMore = false) => {
        ShowWaiting('正在加载...');
        const res = await queryMomentList({
            token,
            schoolId: classInfo.schoolId,
            gradeId:
                curTabIndex === 0 || curTabIndex === 1 ? classInfo.gradeId : 0,
            classId: curTabIndex === 0 ? classInfo.id : 0,
            pageIndex: loadMore ? pageIndex + 1 : 0,
            pageSize: pageSize
        });
        HideWaiting();

        if (res?.result === 0) {
            const list = res.data.content;
            dispatch(
                setClassMomentInfo({
                    momentList: loadMore ? momentList.concat(list) : list,
                    hasMore: list.length === pageSize,
                    pageIndex: loadMore ? pageIndex + 1 : 0
                })
            );
        }
    };

    useEffect(() => {
        fetchMomentList();
    }, [classInfo, token, curTabIndex]);

    function handleRankTabClicked(index) {
        if (index !== curTabIndex) {
            refScroll.current.scrollTop = 0;
            dispatch(
                setClassMomentInfo({
                    curTabIndex: index,
                    pageIndex: 0,
                    momentList: [],
                    hasMore: false
                })
            );
        }
    }

    const handleScroll = useCallback(
        _.throttle(async (e) => {
            e.stopPropagation();
            const { clientHeight, scrollHeight, scrollTop } = e.target;
            if (scrollTop + clientHeight >= scrollHeight && hasMore) {
                await fetchMomentList(true);
            }
        }, 150),
        [hasMore, fetchMomentList]
    );

    const renderTag = (tag, src) => {
        if (!tag) {
            return null;
        }

        return <img className="mr-12 w-[20px] h-[26px]" src={src} alt="" />;
    };

    return (
        <div className="h-full relative">
            <div className="absolute top-0 left-0 right-0 h-[8rem]">
                <ColorTabs
                    tabInfo={rankTabs}
                    index={curTabIndex}
                    onTabClicked={handleRankTabClicked}
                />
            </div>

            <div
                ref={refScroll}
                className="absolute top-[8rem] left-10 right-10 bottom-0 overflow-auto"
                onScroll={handleScroll}
            >
                <Masonry columnsCount={4} gutter="20px">
                    {momentList.map((v) => {
                        return (
                            <div
                                className="bg-white rounded-[14px] overflow-hidden"
                                key={v.momentId}
                                onClick={() => {
                                    navigate(`/moment-detail/${v.momentId}`);
                                }}
                            >
                                <img
                                    className="w-full"
                                    src={config.resInfo.resUrl + '/' + v.res[0]}
                                    alt=""
                                />

                                <div className="py-8 px-4">
                                    <div className="flex mb-2 text-xl text-gray-600">
                                        <span>{v.content}</span>
                                        {v.topics.map((topic) => {
                                            return (
                                                <div
                                                    key={topic}
                                                    className="flex text-blue-500"
                                                >
                                                    <IdName
                                                        nameType={
                                                            NameTypes.Topic
                                                        }
                                                        nameId={parseInt(
                                                            topic,
                                                            10
                                                        )}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <div className="my-3">
                                        {renderTag(true, imgTagClass)}
                                        {renderTag(
                                            v.level > MomentLevel.Class,
                                            imgTagGrade
                                        )}
                                        {renderTag(
                                            v.level === MomentLevel.School,
                                            imgTagSchool
                                        )}
                                    </div>

                                    <div className="flex items-center pt-2">
                                        <IdAvatar
                                            className="w-9 h-9 rounded-full"
                                            nameId={v.userId}
                                        />
                                        <IdName
                                            className="flex-auto pl-2 text-xl text-gray-400"
                                            nameType={NameTypes.User}
                                            nameId={v.userId}
                                        />

                                        <img
                                            className="w-[18px] h-[18px] mr-2"
                                            src={imgComment}
                                            alt=""
                                        />
                                        <span className="text-18 text-gray-600 mr-3">
                                            {v.commentCnt}
                                        </span>

                                        <img
                                            className="w-[18px] h-[18px] mr-2"
                                            src={imgThumbHollow}
                                            alt=""
                                        />
                                        <span className="text-18 text-gray-600">
                                            {v.favoriteCnt}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Masonry>

                {momentList.length > 0 && !hasMore && (
                    <div className="text-center py-8 text-2xl text-gray-500">
                        到底了
                    </div>
                )}
            </div>
        </div>
    );
}
