import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { queryClassLeaveStatListInRange } from '@/network/classapprequest';
import { setLeaveWeekStat } from '@/store/classleavereducer';
import lodash from 'lodash';
import Fan from '@/components/Fan';
import IdName from '@/components/IdName';
export default function ClassInfo() {
    const leaveInfo = useSelector((state) => state.classLeave.stat);
    const userInfo = useSelector((state) => state.userInfo);
    const dispatch = useDispatch();

    let weekLeaveStudentCount = 0;
    let studentCount = 0;
    if (leaveInfo.week) {
        for (let i = 0; i < leaveInfo.week.length; i++) {
            weekLeaveStudentCount += leaveInfo.week[i].cnt;
        }
    }
    if (userInfo?.classInfo?.extendInfo) {
        studentCount = userInfo.classInfo.extendInfo.cntStudent;
    }
    let leavePercent = 0;
    let leaveDayPercent = 0;
    if (studentCount > 0) {
        leavePercent = lodash.floor((100 * weekLeaveStudentCount) / studentCount, 2);
        leaveDayPercent = lodash.floor((100 * weekLeaveStudentCount) / (studentCount * 7), 2);
    }

    useEffect(() => {
        onInitQueryLeaveWeekStatList();
    }, [userInfo.classInfo]);

    async function onInitQueryLeaveWeekStatList() {
        if (!leaveInfo.week) {
            //不存在周数据，需要获取下
            let res = await queryClassLeaveStatListInRange(
                userInfo.token,
                userInfo.classInfo.schoolId,
                userInfo.classInfo.gradeId,
                userInfo.classInfo.id,
                2
            );
            if (res && res.result === 0) {
                //对于班级，返回的是明细
                let leaveStatList = [];
                let objLeaveStat = {};
                for (let i = 0; i < res.data.length; i++) {
                    if (!objLeaveStat[`${res.data[i].type}`]) {
                        objLeaveStat[`${res.data[i].type}`] = {
                            ...res.data[i],
                            student: undefined
                        };
                    } else {
                        objLeaveStat[`${res.data[i].type}`].cnt++;
                    }
                }
                for (let key in objLeaveStat) {
                    leaveStatList.push(objLeaveStat[key]);
                }
                dispatch(setLeaveWeekStat(leaveStatList));
            } else {
                console.log(`----queryClassLeaveStatListInRange return code= ${res.result} msg=${res.msg}`);
            }
        }
    }

    //<Fan className="ml-20 mt-20" width="16rem" innerWidth = "10rem" angle={160} color="#FF0000" bkColor="#FFFFFF" innerColor="#00FF00" />

    function getLeaveStatItemComponents() {
        if (!leaveInfo.week) {
            return null;
        }
        let PieColor = [
            '#ef4444',
            '#8b5cf6',
            '#047857',
            '#C71CC0',
            '#c2410c',
            '#713f12',
            '#1e40af',
            '#831843',
            '#f43f5e',
            '#84cc16'
        ];
        return (
            <>
                {leaveInfo.week.map((item, index) => {
                    let percent = studentCount ? lodash.floor((item.cnt * 100) / (7 * studentCount), 2) : 0;
                    return (
                        <div className="flex flex-col items-center" key={`type_${item.type}`}>
                            <Fan
                                className="m-2"
                                width="7rem"
                                innerWidth="2.5rem"
                                angle={(percent * 360) / 100}
                                borderWidth=""
                                borderColor="#FFFFFF"
                                color={PieColor[index]}
                                bkColor="#b6aCaB"
                                innerColor="#FFFFFF"
                            />
                            <IdName nameId={item.type} nameType={6} />
                            <div className="flex flex-row items-center mt-1">
                                <p>{item.cnt}人,</p>
                                <p className="ml-2">{percent}%</p>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    }

    return (
        <div className="w-full h-full flex flex-col justify-start items-center">
            <div className="w-full flex-none">
                <p className="mt-16 text-4xl text-center">班级名称</p>
                <p className="mt-20 text-3xl text-center">最近7天数据统计</p>
                <div className="w-10 h-2 bg-blue-800 mx-auto rounded mt-1" />
                <div className="flex flex-row justify-around items-center mt-16">
                    <div>
                        <p className="text-3xl  text-center">{studentCount}</p>
                        <p className="text-2xl  text-center">学生人数</p>
                    </div>
                    <div>
                        <p className="text-3xl  text-center">{weekLeaveStudentCount}</p>
                        <p className="text-2xl  text-center">请假人数</p>
                    </div>
                    <div>
                        <p className="text-3xl  text-center">{leavePercent}%</p>
                        <p className="text-2xl  text-center">请假比例</p>
                    </div>
                    <div>
                        <p className="text-3xl  text-center">{leaveDayPercent}%</p>
                        <p className="text-2xl  text-center">平均日假率</p>
                    </div>
                </div>
            </div>
            <div className="w-full h-2 grow px-4 pt-8 pb-4">
                <div className="w-full h-full overflow-y-auto">
                    <div className="w-full flex flex-row flex-wrap justify-around">
                        {getLeaveStatItemComponents()}
                    </div>
                </div>
            </div>
        </div>
    );
}
