import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    today: {},
    week: {},
    weekInfo: {},
    term: {}
};

export const ClassScheduleSlice = createSlice({
    name: 'classSchedule',
    initialState,
    reducers: {
        setScheduleTodayInfo: (state, action) => {
            state.today = action.payload;
        },
        setScheduleWeekInfo: (state, action) => {
            const { week, weekIndex, monday, sunday } = action.payload;
            state.week = week;
            state.weekInfo = {
                weekIndex,
                monday,
                sunday
            };
        },
        setScheduleTermInfo: (state, action) => {
            state.term = action.payload;
        }
    }
});

export const { setScheduleTodayInfo, setScheduleWeekInfo, setScheduleTermInfo } = ClassScheduleSlice.actions;
export default ClassScheduleSlice.reducer;
