import config from '../shared/appconfig';

async function helper(method, url, data = {}, options = {}) {
    let newUrl = null;
    if (url.startsWith('http://') || url.startsWith('https://')) {
        newUrl = url;
    } else {
        newUrl = config.banpaiSvr.domain + url;
    }
    let json = null;
    try {
        if (method === 'GET') {
            const arr = [];
            Object.keys(data).forEach((k) => arr.push(`${k}=${data[k]}`));
            if (newUrl.includes('?')) {
                newUrl += '?' + arr.join('&');
            } else {
                newUrl += '&' + arr.join('&');
            }
        }

        const newOptions = {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            ...options
        };

        if (method === 'POST') {
            newOptions.body = JSON.stringify(data);
        }

        const response = await fetch(newUrl, newOptions);
        try {
            json = await response.json();
        } catch (err) {
            let bodyText = await response.text();
            json = {
                result: -1,
                msg: bodyText
            };
        }
    } catch (err) {
        //console.log("------visit url = [", newUrl, "] error!  \r\n params=", data, "\r\n", err);
        console.log('------visit url = [', newUrl, '] error!  \r\n params=', data, '\r\n error=', err.message);
        json = null;
    }
    return json;
}

export async function post(url, data = {}, options = {}) {
    const response = await helper('POST', url, data, options);
    return response;
}

export async function get(url, data = {}, options = {}) {
    const response = await helper('GET', url, data, options);
    return response;
}
