import ColorTabs from '@/components/colortabs';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { queryClassLeaveDetailListInRange } from '@/network/classapprequest';
import { ShowWaiting, HideWaiting } from '@/components/MsgBox';
import IdAvatar from '@/components/IdAvatar';
import IdName from '@/components/IdName';
import { setLeaveTodayDetail, setLeaveWeekDetail, setLeaveMonthDetail } from '@/store/classleavereducer';

export default function RankInfo() {
    let rankTabs = {
        bkColor: '#23B31200',
        textColor: '#999999',
        bkFocusColor: '#1DB79F',
        textFocusColor: '#ffffff',
        fontSize: '24px',
        margin: '50px',
        border: {
            width: '1px',
            style: 'dashed',
            color: '#999999',
            focusColor: '#999999'
        },
        tabs: [
            {
                content: '当天请假'
            },
            {
                content: '最近7天'
            },
            {
                content: '最近30天'
            }
        ]
    };

    const leaveInfo = useSelector((state) => state.classLeave.detail);
    const userInfo = useSelector((state) => state.userInfo);
    const dispatch = useDispatch();
    const [rankIndex, setRankIndex] = useState(0);
    useEffect(() => {
        onSelectChanged();
    }, [rankIndex, userInfo.classInfo]);

    function onRankTabClicked(index) {
        setRankIndex(index);
    }

    function getLeaveDetailList() {
        if (rankIndex === 0) {
            return leaveInfo?.today;
        } else {
            if (rankIndex === 1) {
                return leaveInfo?.week;
            } else {
                return leaveInfo?.month;
            }
        }
    }

    //(token, schoolId, gradeId, classId, periodType, count) {
    //periodType 0=不限制时间 1=本周 2=本月 3=本学期 4=本学年
    async function onSelectChanged() {
        let showList = getLeaveDetailList();
        let period = rankIndex;
        if (!showList) {
            ShowWaiting('正在请求请假明细统计信息。。。');
            let res = await queryClassLeaveDetailListInRange(userInfo.token, userInfo.classInfo.schoolId, userInfo.classInfo.gradeId, userInfo.classInfo.id, period, 100);
            HideWaiting();
            if (res && res.result === 0) {
                if (rankIndex === 0) {
                    dispatch(setLeaveTodayDetail(res.data));
                } else {
                    if (rankIndex === 1) {
                        dispatch(setLeaveWeekDetail(res.data));
                    } else {
                        dispatch(setLeaveMonthDetail(res.data));
                    }
                }
            } else {
                console.log(`----queryRedFlagRankListInRange return code= ${res.result} msg=${res.msg}`);
            }
        }
    }

    function LeaveItemInfoComponent({ item }) {
        if (item.student === 0) {
            return <div className="w-i-w mt-4 h-28 " />;
        } else {
            let endDate = `${item.endDate.getFullYear()}-${item.endDate.getMonth() + 1}-${item.endDate.getDate()}`;
            return (
                <div className="relative w-i-w mt-4 h-28 border-2 border-dashed bg-white border-c-c flex flex-row justify-between items-center">
                    <div className="flex flex-row justify-start items-center">
                        <IdAvatar className="mx-4 w-16 h-16 rounded-full" nameId={item.student} />
                        <div>
                            <div>
                                <IdName nameId={item.student} nameType={1} />
                                <p className="mt-2">{`还剩: ${item.leaveDays}天`}</p>
                                <p className="mt-2">{`结束时间: ${endDate}`}</p>
                            </div>
                        </div>
                    </div>
                    <div className="absolute right-0 w-full h-full flex flex-row justify-end items-start">
                        {/* <div className="mt-4 mr-4 px-4 py-1 border border-red-700 bg-blue-700/60"> */}
                        <div className="mt-4 mr-4 px-4 py-1 bg-blue-700/60 rounded-lg">
                            <IdName className="text-xl text-white" nameId={item.type} nameType={6} />
                        </div>
                    </div>
                </div>
            );
        }
    }
    function getRankListComponent() {
        let itemList = [];
        let srcList = getLeaveDetailList();
        if (srcList) {
            let nowDate = new Date();
            nowDate = new Date(`${nowDate.getFullYear()}-${nowDate.getMonth()}-${nowDate.getDate()}`);
            srcList.map((item, index) => {
                if (item.isCycle) {
                    //单次请假
                    let endDate = new Date(item.endTime);
                    let leaveDays = Math.floor((endDate.getTime() - nowDate.getTime()) / (3600000 * 24)) + 1;
                    itemList.push({
                        student: item.student,
                        type: item.type,
                        single: !item.isCycle,
                        classId: item.classId,
                        leaveDays,
                        endDate
                    });
                } else {
                    //多次请假
                    let dayOfWeek = nowDate.getDay();
                    if (dayOfWeek === 0) {
                        dayOfWeek = 7;
                    }
                    let endDate = new Date(item.endTime);
                    let leaveDays = Math.floor((endDate.getTime() - nowDate.getTime()) / (3600000 * 24)) + 1;
                    let offset = 1;
                    for (let i = 1; i < dayOfWeek; i++) {
                        offset <<= 1;
                    }
                    let realLeaveDays = 0;
                    for (let i = 0; i < leaveDays; i++) {
                        if ((item.cycleDays & offset) > 0) {
                            realLeaveDays++;
                        }
                        dayOfWeek += 1;
                        if (dayOfWeek > 7) {
                            dayOfWeek = 1;
                            offset = 1;
                        } else {
                            offset <<= 1;
                        }
                    }
                    if (realLeaveDays > 0) {
                        itemList.push({
                            student: item.student,
                            type: item.type,
                            classId: item.classId,
                            single: !item.isCycle,
                            leaveDays: realLeaveDays,
                            endDate
                        });
                    }
                }
            });
            if (itemList.length % 3 === 2) {
                //补上缺省的，当一行只有2个元素时，对齐方式不对，给他加一个空元素
                itemList.push({
                    student: 0,
                    type: 0,
                    single: 0,
                    leaveDays: 0,
                    endDate: null
                });
            }
        }
        return (
            <div className="w-full flex flex-row flex-wrap justify-between mb-4">
                {itemList.map((item, index) => {
                    return <LeaveItemInfoComponent item={item} key={`cls_${index}`} />;
                })}
            </div>
        );
    }

    return (
        <div className="w-full h-full">
            <div className="w-full h-14 mt-11">
                <ColorTabs tabInfo={rankTabs} index={0} onTabClicked={onRankTabClicked} />
            </div>
            <div className="w-5/6 mx-auto my-6" style={{ height: '2px', borderColor: '#999999', borderWidth: '2px', borderStyle: 'dashed', borderColor: '#999999' }} />
            <div className="w-5/6 h-i-h overflow-y-auto mx-auto px-2">
                <div className="w-full flex flex-row flex-wrap justify-between mb-4">{getRankListComponent()}</div>
            </div>
        </div>
    );
}
