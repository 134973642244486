import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import appiconpng from '@/assets/images/logo512.png';
import QRCode from 'qrcode.react';
import miniQRCode from '@/assets/images/miniQRcode.png';
import { queryBanpaiBindInfo } from '@/network/loginbindrequest.js';
import { setKeepAliveInfo, setTimerId, increaseErrCount } from '@/store/keepalivereducer';
import { setDeviceInfo, setDeviceUUID } from '@/store/devicereducer';

import { BindStatus, setBindStatus, setBindInfo } from '@/store/banpaireducer';

let keepAliveLoopCount = 0; //keepalive多少次调用一次

function Bind() {
    const deviceInfo = useSelector((state) => state.deviceInfo);
    const keepaliveInfo = useSelector((state) => state.keepalive);
    let keepAlivePause = false; //是否暂停keepalive
    let dispatch = useDispatch();

    useEffect(() => {
        keepAliveLoopCount--;
        if (keepAliveLoopCount < 0) {
            keepAliveLoopCount = 0;
            queryBanpaiBindInfoFromServer();
        }
    }, [keepaliveInfo.timerId]);

    useEffect(() => {
        onBindPageLoaded();
        return () => {
            onBindPageUnloaded();
        };
    }, []);

    async function onBindPageLoaded() {
        console.log('------------- on BindPage Loaded() -------------------');
    }

    async function onBindPageUnloaded() {
        console.log('------------- on BindPage Unloaded() -------------------');
    }

    //当班牌绑定状态发生改变时调用
    async function queryBanpaiBindInfoFromServer() {
        if (keepAlivePause) {
            return;
        }
        if (!deviceInfo.uuid) {
            if (window.deviceuuid) {
                dispatch(setDeviceUUID(window.deviceuuid));
            }
            return;
        }
        keepAlivePause = true;
        let retBindInfo = await queryBanpaiBindInfo(deviceInfo.uuid);
        keepAliveLoopCount = 1;
        keepAlivePause = false;
        console.log(
            `-----queryBanpaiBindInfoFromServer uuid=${deviceInfo.uuid}  res.result=${retBindInfo?.result}-------`
        );
        if (retBindInfo) {
            if (retBindInfo.result === 0) {
                //成功绑定
                delete retBindInfo.result;
                delete retBindInfo.msg;
                console.log(`-----bindpage 成功绑定 -------`);
                dispatch(setBindInfo(retBindInfo));
                dispatch(setBindStatus(BindStatus.classBind));
            }
        }
    }

    let objQrUrl = {
        uuid: deviceInfo.uuid
    };
    let qrUrl = JSON.stringify(objQrUrl);

    return (
        <div className="w-full h-full relative">
            <div className="w-full h-full flex flex-col items-center justify-center">
                <div className="w-2/3 h-2/3 bg-white rounded-2xl flex flex-col items-center justify-center">
                    <img className="w-20 h-20" src={appiconpng}></img>
                    <p className="text-2xl mt-4">傲华云智智能化班牌</p>
                    <p className="text-5xl mt-12 mb-20 font-bold">学校数字化转型就用云智班牌</p>
                    <div className="flex flex-row text-2xl">
                        <div className="flex flex-col items-center">
                            <img className="w-48 h-48" src={miniQRCode}></img>
                            <p className="mt-4">第一步</p>
                            <p className="mt-4">微信扫一扫进入小程序</p>
                        </div>
                        <div className="flex flex-col items-center ml-40">
                            <QRCode
                                className="w-48 h-48"
                                value={qrUrl}
                                style={{ width: '12rem', height: '12rem' }}
                            ></QRCode>
                            <p className="mt-4">第二步</p>
                            <p className="mt-4">云智校园-班牌管理-扫一扫</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 w-full mb-11 text-2xl flex flex-col items-center">
                <p>北京傲华云智软件有限责任公司</p>
                <p className="mt-2">support@aohuacloud.com</p>
            </div>
        </div>
    );
}

export default Bind;
