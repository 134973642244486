import _ from 'lodash';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

//根据从0点开始的秒数，得到时间串
function getTimeStrFromSeconds(seconds) {
    let hours = _.floor(seconds / 3600, 0);
    let hourSeconds = hours * 3600;
    let minutes = _.floor((seconds - hourSeconds) / 60, 0);
    let strHours = _.padStart(hours, 2, '0');
    let strMinutes = _.padStart(minutes, 2, '0');
    return strHours + ':' + strMinutes;
}

//获取当前时间相对于0点开始的秒数
function getDaySecondsFromTime(time) {
    let hours = time.getHours();
    let minutes = time.getMinutes();
    let seconds = time.getSeconds();
    return seconds + minutes * 60 + hours * 3600;
}

//获取给定时间位于课间什么位置
function getClassIntervalInfoFromDate(term, time) {
    let curClassType = 0; //当前上课类型   =0  课外休息   =1   课间休息    =2  正在上课
    let curClassIndex = -1; //当前第几节课
    if (term) {
        let timeValue = getDaySecondsFromTime(time);
        let maxClassIndex = term.classCount;
        if (maxClassIndex > 0) {
            if (
                timeValue >= term['1'].classStart &&
                timeValue <= term[`${maxClassIndex}`].classEnd
            ) {
                for (let i = 1; i <= maxClassIndex; i++) {
                    if (timeValue < term[`${i}`].classStart) {
                        curClassType = 1;
                        curClassIndex = i - 1;
                        break;
                    }
                    if (
                        timeValue >= term[`${i}`].classStart &&
                        timeValue <= term[`${i}`].classEnd
                    ) {
                        curClassType = 2;
                        curClassIndex = i;
                        break;
                    }
                }
            }
        }
    }
    return {
        curClassType,
        curClassIndex
    };
}

const formatDuration = (date) => {
    let time1 = dayjs(date).valueOf();
    let time2 = dayjs(Date.now()).valueOf();

    if (time2 - time1 < 1000 * 60 * 1) {
        return '刚刚';
    } else if (time2 - time1 < 1000 * 60 * 60) {
        return _.round(dayjs.duration(time2 - time1).asMinutes(), 0) + '分钟前';
    } else if (time2 - time1 < 1000 * 60 * 60 * 24) {
        return _.round(dayjs.duration(time2 - time1).asHours(), 0) + '小时前';
    } else if (time2 - time1 < 1000 * 60 * 60 * 24 * 2) {
        return '昨天';
    } else if (time2 - time1 < 1000 * 60 * 60 * 24 * 4) {
        return _.round(dayjs.duration(time2 - time1).asDays(), 0) + '天前';
    } else {
        return dayjs(date).format('YYYY-MM-DD');
    }
};

export default {
    getTimeStrFromSeconds,
    getDaySecondsFromTime,
    getClassIntervalInfoFromDate,
    formatDuration
};
