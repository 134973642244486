import Avatarpng from '@/assets/images/Avatar.png';
import { useSelector } from 'react-redux';
import IdAvatar from '@/components/IdAvatar';
import IdName from '@/components/IdName';
import { NameTypes } from '@/shared/constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMineInfo } from '@/store/mine-reducer';
import { addSwipCardInfo } from '@/store/cardreaderreducer';
import { asyncCallNative, callNative } from 'hhjsshell';

export default function LeaveMsgInfo() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const messageList = useSelector((state) => state.classMessage.messageList);

    function onClick() {
        dispatch(setMineInfo({ prepareToEnterMine: true }));
        //开始人脸识别
        asyncCallNative('appobject', 'startFaceDetect', {}).then((data) => {
            //let tips = `--------on face Recognize return userId=${data.userId} ----fileName=${data.fileName}`;
            //console.log(tips);
            if (data.userId > 0) {
                dispatch(addSwipCardInfo({ userId: data.userId }));
            } else {
                dispatch(setMineInfo({ prepareToEnterMine: false }));
            }
        });
    }

    return (
        <div className="w-full h-t-h px-3 py-2" onClick={onClick} alt="minepage">
            <div className="relative bg-white/60 w-full h-full rounded-2xl">
                <div className="w-full h-24 flex flex-col items-center justify-center">
                    <p className="text-3xl">传音螺</p>
                </div>
                <div className="h-5/6 overflow-y-auto">
                    <div className="w-full flex flex-col justify-start items-start">
                        {messageList.map((v) => {
                            return <LeavemsgItem userId={v.userId} count={v.unreadCnt} key={`${v.userId}`} />;
                        })}
                        {/* <LeavemsgItem />
            <LeavemsgItem />
            <LeavemsgItem />
            <LeavemsgItem />
            <LeavemsgItem />
            <LeavemsgItem />
            <LeavemsgItem />
            <LeavemsgItem />
            <LeavemsgItem />
            <LeavemsgItem /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

function LeavemsgItem({ userId, count }) {
    return (
        <div className="mx-4 h-20 flex flex-row justify-start items-center">
            <IdAvatar className="w-14 h-14 mr-4 rounded-full" nameId={userId} />
            {/* <img className="w-14 h-14 mr-4 rounded-full" src={Avatarpng}></img> */}
            <div className="flex flex-col justify-center items-start">
                <div className="text-xl flex">
                    <IdName nameType={NameTypes.User} nameId={userId} />
                    同学
                </div>
                <p className="text-lg">您有{count}条家长留言</p>
            </div>
        </div>
    );
}
