import { Routes, Route } from 'react-router-dom';
import Home from '@/pages/home';
import Launch from '@/pages/launch';
import Bind from '@/pages/bind';
import ClassMoment from '@/pages/moment';
import MinePage from '@/pages/mine';
import Chat from '@/pages/mine/chat';
import RedFlag from '@/pages/redflag';
import RedFlower from '@/pages/redflower';
import LeaveInfo from '@/pages/leaveinfo';
import ClassSchedule from '@/pages/classschedule';
import MomentDetail from '@/pages/moment/detail';

function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Launch />}>
                    <Route path="home" element={<Home />}></Route>
                    <Route path="bind" element={<Bind />}></Route>
                    <Route path="moment" element={<ClassMoment />}></Route>
                    <Route path="moment-detail/:momentId" element={<MomentDetail />}></Route>
                    <Route path="mine" element={<MinePage />}></Route>
                    <Route path="chat/:parentId" element={<Chat />}></Route>
                    <Route path="redflag" element={<RedFlag />}></Route>
                    <Route path="leaveinfo" element={<LeaveInfo />}></Route>
                    <Route path="classschedule" element={<ClassSchedule />}></Route>
                    <Route path="redflower" element={<RedFlower />}></Route>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
