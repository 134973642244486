import { useState } from 'react';
export default function ColorTabs({ tabInfo, index, onTabClicked }) {
    const [currentIndex, setCurrent] = useState(index);

    function onClicked(index) {
        setCurrent(index);
        if (onTabClicked) {
            onTabClicked(index);
        }
    }
    return (
        <div className="w-full h-full flex flex-row justify-center items-center">
            {tabInfo.tabs.map((item, index) => {
                return (
                    <div
                        key={`item${index}`}
                        className="rounded px-10 py-2"
                        style={{
                            fontSize: tabInfo.fontSize,
                            backgroundColor: index === currentIndex ? tabInfo.bkFocusColor : tabInfo.bkColor,
                            color: index === currentIndex ? tabInfo.textFocusColor : tabInfo.textColor,
                            marginLeft: tabInfo.margin,
                            marginRight: tabInfo.margin,
                            borderWidth: tabInfo.border ? tabInfo.border.width : '0px',
                            borderStyle: tabInfo.border ? tabInfo.border.style : 'solid',
                            borderColor: index === currentIndex ? (tabInfo.border ? tabInfo.border.focusColor : '#00000000') : tabInfo.border ? tabInfo.border.color : '#00000000'
                        }}
                        onClick={() => onClicked(index)}
                    >
                        {item.content}
                        {tabInfo.indicate && index === currentIndex ? (
                            <div
                                style={{
                                    width: tabInfo.indicate.width,
                                    height: tabInfo.indicate.height,
                                    backgroundColor: tabInfo.indicate.color,
                                    borderRadius: tabInfo.indicate.radius,
                                    marginTop: tabInfo.indicate.topGap,
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}
                            />
                        ) : null}
                    </div>
                );
            })}
        </div>
    );
}
