import ClassInfo from './components/classinfo';
import RankInfo from './components/rankinfo';
function RedFlagContent() {
    //0.739
    //left gap 0.01354
    //  0.282 0.691
    return (
        <div className="w-full h-content flex flex-row justify-start items-start">
            <div className="w-al-w h-full ml-l-gap flex-none flex flex-col justify-start items-center p-2">
                <div className="w-full h-full bg-white/60 rounded-2xl">
                    <ClassInfo />
                </div>
            </div>
            <div className="w-ar-w  h-full flex-none  flex flex-col justify-start items-center p-2">
                <div className="w-full h-full bg-white/60 rounded-2xl">
                    <RankInfo />
                </div>
            </div>
        </div>
    );
}

export default RedFlagContent;
