import _ from 'lodash';
import './index.css';

export default function Wave() {
    return (
        <div className="ah-wave">
            {_.range(15).map((v) => {
                return <div key={v} className="ah-wave-bar" />;
            })}
        </div>
    );
}
