import Header from '@/components/Header';
import Tailer from '@/components/Tailer';
import Content from './redflagcontent';
export default function RedFlag() {
    console.log('------------- on RedFlag() -------------------');

    return (
        <div className="w-full h-full flex flex-col">
            <Header />
            <Content />
            <Tailer />
        </div>
    );
}
