import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    timerId: 0, //定时器Id
    errCount: 0, //出错次数
    syncCount: 0, //同步次数
    info: {} //激活信息
};

export const KeepAliveSlice = createSlice({
    name: 'keepalive',
    initialState,
    reducers: {
        setTimerId: (state, action) => {
            state.timerId = action.payload;
        },
        increaseErrCount: (state) => {
            state.errCount++;
        },
        increaseSyncCount: (state) => {
            state.syncCount++;
        },
        setKeepAliveInfo: (state, action) => {
            state.errCount = 0;
            state.info = action.payload;
        }
    }
});

export const { setKeepAliveInfo, setTimerId, increaseErrCount, increaseSyncCount } = KeepAliveSlice.actions;
export default KeepAliveSlice.reducer;
