import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    prepareToEnterMine: false,
    studentId: 0,
    parentList: []
};

const mineSlice = createSlice({
    name: 'mine',
    initialState,
    reducers: {
        setMineInfo: (state, action) => {
            Object.assign(state, action.payload);
        }
    }
});

export const { setMineInfo } = mineSlice.actions;
export default mineSlice.reducer;
