import { createSlice } from '@reduxjs/toolkit';

const LoginStatus = {
    noInit: 0, //未初始化
    noLogin: 1, //未登陆
    onProcess: 2, //登陆过程中
    loginSuccess: 3, //成功登陆
    loginfailed: 4, //登陆失败
    loginRetry: 5 //重新登陆
};

const initialState = {
    loginStatus: LoginStatus.noInit, //登录状态
    token: '',
    userInfo: {}, //用户信息
    classInfo: {}, //用户信息
    schoolInfo: {} //用户信息
};

export const UserInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        setLoginStatus: (state, action) => {
            state.loginStatus = action.payload;
        },
        setUserInfo(state, action) {
            state.userInfo = action.payload;
        },
        setClassInfo(state, action) {
            state.classInfo = action.payload;
        },
        setSchoolInfo(state, action) {
            state.schoolInfo = action.payload;
        },
        setUserToken(state, action) {
            state.token = action.payload;
        }
    }
});

export const { setUserInfo, setLoginStatus, setUserToken, setClassInfo, setSchoolInfo } =
    UserInfoSlice.actions;
export { LoginStatus };
export default UserInfoSlice.reducer;
