import Header from '@/components/Header';
import Tailer from '@/components/Tailer';
import Content from './classschedulecontent'
export default function ClassSchedule() {
    console.log('------------- on ClassSchedule() -------------------');

    return (
        <div className="w-full h-full flex flex-col">
            <Header />
            <Content/>
            <Tailer />
        </div>
    );
}
