import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    listener: null, //监听函数
    swipCnt: 0, //刷卡次数
    cardInfo: [] //已经刷卡但未处理的卡信息
};

export const CardReaderInfoSlice = createSlice({
    name: 'cardReader',
    initialState,
    reducers: {
        setListener: (state, action) => {
            state.listener = action.payload;
        },
        addSwipCardInfo: (state, action) => {
            state.swipCnt++;
            state.cardInfo.push(action.payload);
        },
        removeSwipCardInfo: (state, action) => {
            state.cardInfo.shift();
        },
        clearSwipCardInfo: (state, action) => {
            state.cardInfo = [];
        }
    }
});

export const { setListener, addSwipCardInfo, removeSwipCardInfo, clearSwipCardInfo } =
    CardReaderInfoSlice.actions;
export default CardReaderInfoSlice.reducer;
