import ClassInfo from './components/classinfo';
import ScheduleInfo from './components/scheduleinfo';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import utils from '@/shared/utils';

function ClassScheduleContent() {
    //0.739
    //left gap 0.01354
    //  0.282 0.691
    const keepaliveInfo = useSelector((state) => state.keepalive);
    const classSchedule = useSelector((state) => state.classSchedule);
    const appSettings = useSelector((state) => state.appSettingsInfo);
    const [curScheduleInfo, setCurScheduleInfo] = useState({
        type: 0, //当前上课类型   =0  课外休息   =1   课间休息    =2  正在上课
        curClassIndex: -1 //当前第几节课
    });

    //let nowDate = new Date('2023-10-11 14:36:00');
    let nowDate = new Date();
    //console.log(nowDate);
    let dayOfWeek = nowDate.getDay(); //当天星期几    1-7   周一到周日
    if (dayOfWeek === 0) {
        dayOfWeek = 7;
    }
    let todayClasses = {};
    for (let i = 0; i < classSchedule.week.length; i++) {
        if (classSchedule.week[i].dayOfWeek === dayOfWeek) {
            todayClasses[`${classSchedule.week[i].classIndex}`] = classSchedule.week[i];
        }
    }

    let classInfoOffset = 0;
    let itemWidth = 7; //每个课程宽度 = 7rem
    let itemGap = 1; //每个课程左右间隔 = 1rem
    let htmlFontSize = appSettings.fontSize;
    if (curScheduleInfo.type === 2) {
        //正在上课，计算当前课程偏移
        if (curScheduleInfo.curClassIndex > 1) {
            classInfoOffset = (curScheduleInfo.curClassIndex - 2) * (itemWidth + itemGap) * htmlFontSize;
        }
    }

    useEffect(() => {
        onTimer();
    }, [keepaliveInfo.timerId, classSchedule.week, classSchedule.term]);

    async function onTimer() {
        let curClassInfo = utils.getClassIntervalInfoFromDate(classSchedule.term, nowDate);
        if (curClassInfo.curClassType !== curScheduleInfo.type || curClassInfo.curClassIndex !== curScheduleInfo.curClassIndex) {
            setCurScheduleInfo({
                type: curClassInfo.curClassType,
                curClassIndex: curClassInfo.curClassIndex
            });
        }
    }

    return (
        <div className="w-full h-content flex flex-row justify-start items-start">
            <div className="w-al-w h-full ml-l-gap flex-none flex flex-col justify-start items-center p-2">
                <div className="w-full h-full bg-white/60 rounded-2xl">
                    <ClassInfo offset={classInfoOffset} classList={todayClasses} classType={curScheduleInfo.type} curClass={curScheduleInfo.curClassIndex} dayOfWeek={dayOfWeek} />
                </div>
            </div>
            <div className="w-ar-w  h-full flex-none  flex flex-col justify-start items-center p-2">
                <div className="w-full h-full bg-white/60 rounded-2xl">
                    <ScheduleInfo classType={curScheduleInfo.type} curClass={curScheduleInfo.curClassIndex} dayOfWeek={dayOfWeek} />
                </div>
            </div>
        </div>
    );
}

export default ClassScheduleContent;
