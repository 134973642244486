export const APP_ID = 10000;

export const WeekDays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
export const WeekSimpleNames = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'];

export const UserRoles = {
    student: 0x01,
    parent: 0x02,
    teacher: 0x10,
    master: 0x20,
    gradeManager: 0x40,
    schoolManager: 0x80,
    superPriviledge: 0x10000000
};

export const ChineseNumber = {
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
    7: '七',
    8: '八',
    9: '九',
    10: '十'
};

export const NameTypes = {
    User: 1,
    School: 2,
    Grade: 3,
    Class: 4,
    Topic: 5,
    LeaveType: 6,
    FlowerType: 7,
    FlagType: 8,
    Subject: 9
};

export const MomentLevel = {
    All: 0,
    Waiting: 1, // 未审核
    Rejected: 2, // 审核驳回
    Passed: 3, // 审核通过
    Class: 4, // 班级风采
    Grade: 5, // 年级风采
    School: 6 // 学校风采
};

export const ModePropName = {
    10: '父亲',
    11: '母亲',
    12: '爷爷',
    13: '奶奶',
    14: '姥爷',
    15: '姥姥',
    20: '家长'
};

export const MessageType = {
    Text: 0,
    Picture: 1,
    Audio: 2,
    Video: 3
};

export const AudioFormat = {
    AMR_NB: 1,
    AMR_WB: 2,
    AAC: 3,
    HE_AAC: 4,
    AAC_ELD: 5,
    VORBIS: 6,
    OPUS: 7
};
