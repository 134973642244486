import { asyncCallNative } from 'hhjsshell';
import { AudioFormat } from '../../shared/constants';

const ERR = {
    noGetUserMedia: { code: 0, msg: '不支持语音输入 ' },
    noDevice: { code: 1, msg: '请设置麦克风' }
};

class NativeRecorder {
    constructor({ onStart, onStop, onError } = {}) {
        this.onStart = onStart;
        this.onStop = onStop;
        this.onError = onError;
        this.init();

        this.chunks = [];
        this.startTime = 0;
        this.timer = null;
    }

    async init() {}

    start() {
        this.timer = setTimeout(() => {
            this.stop();
        }, 60 * 1000);
        this.startTime = Date.now();

        asyncCallNative('appobject', 'startAudioRecord', {
            mediaEncode: true,
            audioEncodec: AudioFormat.AAC
        }).then((data) => {
            const strData = data.data;
            const dataLen = data.length;
            if (data.result === 0) {
                console.log(`录音成功原始数据长度${dataLen} 字符串数据长度=${strData.length}`);
                this.onStop &&
                    this.onStop({
                        fileBase64: data.data,
                        length: data.length,
                        duration: Date.now() - this.startTime
                    });
            } else {
                console.log(`录音出错，错误号${data.result} 错误码：${data.msg}`);
            }
        });

        this.onStart && this.onStart();
        console.log('recorder start');
    }

    stop() {
        clearTimeout(this.timer);
        this.timer = null;

        asyncCallNative('appobject', 'stopAudioRecord', {}).then(() => {
            console.log('录音结束');
        });
        console.log('recorder stop');
    }

    download(blob) {
        // const downloadElement = document.createElement('a');
        // const href = window.URL.createObjectURL(blob);
        // downloadElement.href = href;
        // downloadElement.download = 'audio.ogg';
        // document.body.appendChild(downloadElement);
        // downloadElement.click();
        // document.body.removeChild(downloadElement);
        // window.URL.revokeObjectURL(href);
    }
}

export default NativeRecorder;
