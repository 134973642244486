import ColorTabs from '@/components/colortabs';
import FlowerFlag from '@/assets/images/flowerflag.png';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { queryRedFlowerRankListInRange } from '@/network/classapprequest';
import { ShowWaiting, HideWaiting } from '@/components/MsgBox';
import IdAvatar from '@/components/IdAvatar';
import IdName from '@/components/IdName';
import { setFlowerTodayStat, setFlowerWeekStat, setFlowerMonthStat, setFlowerTermStat, setFlowerYearStat } from '@/store/redflowerreducer';
export default function RankInfo() {
    let rankTabs = {
        bkColor: '#23B31200',
        textColor: '#999999',
        bkFocusColor: '#1DB79F',
        textFocusColor: '#ffffff',
        fontSize: '24px',
        margin: '50px',
        border: {
            width: '1px',
            style: 'dashed',
            color: '#999999',
            focusColor: '#999999'
        },
        tabs: [
            {
                content: '班级榜'
            },
            {
                content: '年级榜'
            },
            {
                content: '全校榜'
            }
        ]
    };
    let rangeTabs = {
        bkColor: '#23B31200',
        textColor: '#999999',
        bkFocusColor: '#FFB31200',
        textFocusColor: '#EC3834',
        fontSize: '28px',
        margin: '20px',
        indicate: {
            width: '30px',
            height: '3px',
            color: '#EC3834',
            borderRadius: '2px',
            marginTop: '10px'
        },
        tabs: [
            {
                content: '日榜'
            },
            {
                content: '周榜'
            },
            {
                content: '月榜'
            },
            {
                content: '期榜'
            },
            {
                content: '年榜'
            }
        ]
    };

    const flowerInfo = useSelector((state) => state.redFlower);
    const userInfo = useSelector((state) => state.userInfo);
    const dispatch = useDispatch();
    const [selectInfo, setSelectInfo] = useState({
        rank: 0,
        range: 0
    });
    useEffect(() => {
        onSelectChanged();
    }, [selectInfo,userInfo.classInfo]);

    function onRankTabClicked(index) {
        setSelectInfo({
            ...selectInfo,
            rank: index
        });
    }

    function onRangeTabClicked(index) {
        setSelectInfo({
            ...selectInfo,
            range: index
        });
    }

    function getRedFlowerList() {
        switch (selectInfo.rank) {
            case 0:
                switch (selectInfo.range) {
                    case 0:
                        return flowerInfo?.class?.today;
                    case 1:
                        return flowerInfo?.class?.week;
                    case 2:
                        return flowerInfo?.class?.month;
                    case 3:
                        return flowerInfo?.class?.term;
                    default:
                        return flowerInfo?.class?.year;
                }
                break;
            case 1:
                switch (selectInfo.range) {
                    case 0:
                        return flowerInfo?.grade?.today;
                    case 1:
                        return flowerInfo?.grade?.week;
                    case 2:
                        return flowerInfo?.grade?.month;
                    case 3:
                        return flowerInfo?.grade?.term;
                    default:
                        return flowerInfo?.grade?.year;
                }
                break;
            default:
                switch (selectInfo.range) {
                    case 0:
                        return flowerInfo?.school?.today;
                    case 1:
                        return flowerInfo?.school?.week;
                    case 2:
                        return flowerInfo?.school?.month;
                    case 3:
                        return flowerInfo?.school?.term;
                    default:
                        return flowerInfo?.school?.year;
                }
                break;
        }
    }

    function setFlowerListData(list) {
        switch (selectInfo.rank) {
            case 0:
                switch (selectInfo.range) {
                    case 0:
                        dispatch(setFlowerTodayStat({ range: 'class', list }));
                        break;
                    case 1:
                        dispatch(setFlowerWeekStat({ range: 'class', list }));
                        break;
                    case 2:
                        dispatch(setFlowerMonthStat({ range: 'class', list }));
                        break;
                    case 3:
                        dispatch(setFlowerTermStat({ range: 'class', list }));
                        break;
                    default:
                        dispatch(setFlowerYearStat({ range: 'class', list }));
                        break;
                }
                break;
            case 1:
                switch (selectInfo.range) {
                    case 0:
                        dispatch(setFlowerTodayStat({ range: 'grade', list }));
                        break;
                    case 1:
                        dispatch(setFlowerWeekStat({ range: 'grade', list }));
                        break;
                    case 2:
                        dispatch(setFlowerMonthStat({ range: 'grade', list }));
                        break;
                    case 3:
                        dispatch(setFlowerTermStat({ range: 'grade', list }));
                        break;
                    default:
                        dispatch(setFlowerYearStat({ range: 'grade', list }));
                        break;
                }
                break;
            default:
                switch (selectInfo.range) {
                    case 0:
                        dispatch(setFlowerTodayStat({ range: 'school', list }));
                        break;
                    case 1:
                        dispatch(setFlowerWeekStat({ range: 'school', list }));
                        break;
                    case 2:
                        dispatch(setFlowerMonthStat({ range: 'school', list }));
                        break;
                    case 3:
                        dispatch(setFlowerTermStat({ range: 'school', list }));
                        break;
                    default:
                        dispatch(setFlowerYearStat({ range: 'school', list }));
                        break;
                }
                break;
        }
    }

    //(token, schoolId, gradeId, classId, periodType, count) {
    //periodType 0=不限制时间 1=本周 2=本月 3=本学期 4=本学年
    async function onSelectChanged() {
        let showList = getRedFlowerList();
        let period = 10;
        if (selectInfo.range > 0 && selectInfo.range < 5) {
            period = selectInfo.range;
        }
        if (!showList) {
            ShowWaiting('正在请求红花数据。。。');
            let res = await queryRedFlowerRankListInRange(
                userInfo.token,
                userInfo.classInfo.schoolId,
                selectInfo.rank < 2 ? userInfo.classInfo.gradeId : 0,
                selectInfo.rank === 0 ? userInfo.classInfo.id : 0,
                period,
                100
            );
            HideWaiting();
            if (res && res.result === 0) {
                setFlowerListData(res.data);
            } else {
                console.log(`----queryRedFlowerRankListInRange return code= ${res.result} msg=${res.msg}`);
            }
        }
    }

    function UserInfoComponent({ item }) {
        if (item.student === 0) {
            return <div className="w-i-w mt-4 h-28" />;
        } else {
            return (
                <div className="w-i-w mt-4 h-28 border-2 border-dashed bg-white border-c-c flex flex-row justify-between items-center">
                    <div className="flex flex-row justify-start items-center">
                        <IdAvatar className="mx-4 w-16 h-16 rounded-full" nameId={item.student} />
                        <div>
                            <IdName nameId={item.student} nameType={1} />
                            <IdName nameId={item.classId} nameType={4} />
                        </div>
                    </div>
                    <div className="flex flex-row justify-end items-center">
                        <p>{item.count}</p>
                        <img className="w-10 h-10 mx-4" src={FlowerFlag} />
                    </div>
                </div>
            );
        }
    }

    function getRankListComponent() {
        let itemList = [];
        let srcList = getRedFlowerList();
        if (srcList) {
            for (let i = 0; i < srcList.length; i++) {
                itemList.push(srcList[i]);
            }
            if (itemList.length % 3 === 2) {
                //补上缺省的，当一行只有2个元素时，对齐方式不对，给他加一个空元素
                itemList.push({
                    schoolId: 0,
                    gradeId: 0,
                    classId: 0,
                    student: 0,
                    count: 0
                });
            }
        }
        return (
            <div className="w-full flex flex-row flex-wrap justify-between mb-4">
                {itemList.map((item) => {
                    return <UserInfoComponent item={item} key={`stu_${item.student}`} />;
                })}
            </div>
        );
    }

    return (
        <div className="w-full h-full">
            <div className="w-full h-14 mt-11">
                <ColorTabs tabInfo={rankTabs} index={0} onTabClicked={onRankTabClicked} />
            </div>
            <div className="w-full h-16 mt-8">
                <ColorTabs tabInfo={rangeTabs} index={0} onTabClicked={onRangeTabClicked} />
            </div>
            <div className="w-5/6 mx-auto my-6" style={{ height: '2px', borderColor: '#999999', borderWidth: '2px', borderStyle: 'dashed', borderColor: '#999999' }} />
            <div className="w-5/6 h-i-h overflow-y-auto mx-auto px-2">{getRankListComponent()}</div>
        </div>
    );
}
