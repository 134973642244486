import IdName from '@/components/IdName';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { queryRedFlagRankListInRange } from '@/network/classapprequest';
import { setRedFlagMonthStat } from '@/store/redflagreducer';
import lodash from 'lodash';

export default function ClassInfo() {
    const redFlagInfo = useSelector((state) => state.redFlag);
    const userInfo = useSelector((state) => state.userInfo);
    const dispatch = useDispatch();

    useEffect(() => {
        onInitQueryMonthList();
    }, [userInfo.classInfo]);

    let MonthRedFlagList = redFlagInfo?.school?.month;
    let MonthFlagCount = 0;
    let studentCount = 0;
    if (MonthRedFlagList) {
        for (let i = 0; i < MonthRedFlagList.length; i++) {
            if (MonthRedFlagList[i].classId === userInfo.classInfo.id) {
                MonthFlagCount += MonthRedFlagList[i].count;
            }
        }
    }
    if (userInfo?.classInfo?.extendInfo) {
        studentCount = userInfo.classInfo.extendInfo.cntStudent;
    }

    async function onInitQueryMonthList() {
        if (!redFlagInfo?.school?.month) {
            //不存在月数据，需要获取下
            let res = await queryRedFlagRankListInRange(
                userInfo.token,
                userInfo.classInfo.schoolId,
                0,
                0,
                2,
                100
            );
            if (res && res.result === 0) {
                dispatch(setRedFlagMonthStat({ range: 'school', list: res.data }));
            } else {
                console.log(`----queryRedFlagRankListInRange return code= ${res.result} msg=${res.msg}`);
            }
        }
    }

    function getSchoolRedFlagTop3() {
        let RedFlagTop3 = [];
        if (MonthRedFlagList) {
            for (let i = 0; i < MonthRedFlagList.length; i++) {
                RedFlagTop3.push(MonthRedFlagList[i]);
                if (i >= 2) {
                    break;
                }
            }
            for (let i = MonthRedFlagList.length; i < 3; i++) {
                RedFlagTop3.push({
                    classId: -i,
                    count: 0
                });
            }
        }
        return (
            <div className="mt-10 flex flex-row justify-around items-center">
                {RedFlagTop3.map((item, index) => {
                    if (item.classId <= 0) {
                        return <div key={`top3_${item.classId}`} className="w-1/5"></div>;
                    } else {
                        return (
                            <div key={`top3_${item.classId}`}>
                                <div className="w-16 h-16 border-red-600 border border-2 rounded-full flex flex-row justify-center items-center mx-auto">
                                    <p className="text-2xl">{index + 1}</p>
                                </div>
                                <IdName
                                    className="text-2xl  text-center mt-4"
                                    nameId={item.classId}
                                    nameType={4}
                                />
                                <p className="text-2xl  text-center mt-4">{item.count}</p>
                            </div>
                        );
                    }
                })}
            </div>
        );
    }

    return (
        <div className="w-full">
            <p className="mt-16 text-4xl text-center">班级名称</p>
            <p className="mt-20 text-3xl text-center">班级统计</p>
            <div className="w-10 h-2 bg-blue-800 mx-auto rounded mt-1" />
            <div className="flex flex-row justify-around items-center mt-16">
                <div>
                    <p className="text-3xl  text-center">{studentCount}</p>
                    <p className="text-2xl  text-center">学生人数</p>
                </div>
                <div>
                    <p className="text-3xl  text-center">{MonthFlagCount}</p>
                    <p className="text-2xl  text-center">本月获得</p>
                </div>
                <div>
                    <p className="text-3xl  text-center">{redFlagInfo.redFlagCount}</p>
                    <p className="text-2xl  text-center">累计获得</p>
                </div>
            </div>
            <div className="my-12 border border-stone-600 border-dashed mx-6" />
            <p className="text-2xl  text-center">本月全校TOP3</p>
            {getSchoolRedFlagTop3()}
        </div>
    );
}
