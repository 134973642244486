import classNames from 'classnames';
import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

var msgboxStatus = {
    showCount: 0,
    showWaiting: false,
    showMessage: false,
    title: '',
    content: '',
    btn: 0,
    onClick: null,
    rootRender: null
};
function MsgBox({ showWaiting, showMessage, title, content, btn }) {
    let bShowContainer = showWaiting || showMessage;
    return (
        <div
            className={classNames(
                'fixed z-50 left-0 top-0 w-screen h-screen flex-col justify-center items-center',
                bShowContainer ? 'flex' : 'hidden'
            )}
        >
            <div
                className={classNames(
                    'flex flex-col justify-start items-center relative text-2xl rounded-xl',
                    showMessage && btn > 0
                        ? 'text-gray-900 bg-white border-gray-200 border-2 border-solid shadow-xl shadow-gray-900'
                        : 'text-white bg-black/70'
                )}
            >
                {showWaiting ? (
                    <WaitingMsgBox content={content} />
                ) : (
                    <MessageMsgBox content={content} title={title} btn={btn} />
                )}
            </div>
        </div>
    );
}

function ItemGap({ hgap, vgap }) {
    return <div style={{ width: `${hgap}px`, height: `${vgap}px` }} />;
}

function WaitingMsgBox({ content }) {
    if (content.length > 0) {
        return (
            <div className="flex flex-col justify-center items-center p-10">
                <img className="w-44 h-40 animate-spin-slow" src="loading.png" />
                <ItemGap hgap={10} vgap={10} />
                <p className="text-3xl text-gray-200">{content}</p>
            </div>
        );
    } else {
        return (
            <div className="flex flex-col justify-center items-center m-10">
                <img className="w-44 h-40 animate-spin-slow" src="loading.png" />
            </div>
        );
    }
}

function MessageMsgBox({ title, content, btn }) {
    if (btn === 0) {
        return (
            <div className="flex flex-col justify-start items-center px-5 py-10 max-w-xl min-w-[20rem]">
                <p>{title}</p>
                <ItemGap hgap={10} vgap={10} />
                <p>{content}</p>
                <ItemGap hgap={10} vgap={10} />
            </div>
        );
    } else {
        return (
            <div className="flex flex-col justify-start items-center px-5 py-10 max-w-xl min-w-[24rem]">
                <p>{title}</p>
                <ItemGap hgap={10} vgap={10} />
                <p>{content}</p>
                <ItemGap hgap={10} vgap={10} />
                <MessageBtnContainer btn={btn} />
            </div>
        );
    }
}

function MessageBtnContainer({ btn }) {
    return (
        <div
            className={classNames(
                'w-5/6 flex flex-row items-center mt-4',
                btn === 1 ? 'justify-center' : 'justify-around'
            )}
        >
            <div
                className="py-2 px-8 bg-btn-bkclr text-white rounded-lg"
                onClick={() => {
                    onBtnClick(0);
                }}
            >
                确定
            </div>
            {btn > 1 ? (
                <div
                    className="py-2 px-8 bg-btn-bkclr text-white rounded-lg"
                    onClick={() => {
                        onBtnClick(1);
                    }}
                >
                    取消
                </div>
            ) : null}
        </div>
    );
}

function onBtnClick(index) {
    let onClick = msgboxStatus.onClick;
    CloseMsgBox();
    if (onClick) {
        onClick(index);
    }
}

function mountMsgBox() {
    if (!document.getElementById('____MsgBox____')) {
        let div = document.createElement('div');
        div.setAttribute('id', '____MsgBox____');
        document.body.appendChild(div);
        msgboxStatus.rootRender = createRoot(div);
        renderMsgBox();
    }
}

function renderMsgBox() {
    if (msgboxStatus.rootRender) {
        msgboxStatus.rootRender.render(
            <MsgBox
                showWaiting={msgboxStatus.showWaiting}
                showMessage={msgboxStatus.showMessage}
                title={msgboxStatus.title}
                content={msgboxStatus.content}
                btn={msgboxStatus.btn}
            />
        );
    }
}

function unMountMsgBox() {
    msgboxStatus.rootRender = null;
    if (document.getElementById('____MsgBox____')) {
        document.getElementById('____MsgBox____').remove();
    }
}

function ShowWaiting(content) {
    // const dispatch = store.dispatch;
    // dispatch(showWaiting(content));
    if (msgboxStatus.onClick) {
        return false;
    }
    msgboxStatus.showCount++;
    msgboxStatus.showWaiting = true;
    msgboxStatus.showMessage = false;
    msgboxStatus.title = '';
    msgboxStatus.content = content;
    msgboxStatus.btn = 0;
    msgboxStatus.onClick = null;
    if (msgboxStatus.showCount === 1) {
        mountMsgBox();
    } else {
        renderMsgBox();
    }
}

function HideWaiting() {
    // const dispatch = store.dispatch;
    // dispatch(hideWaiting());
    if (msgboxStatus.showCount > 0) {
        msgboxStatus.showCount--;
        if (msgboxStatus.showCount === 0) {
            msgboxStatus.onClick = null;
            unMountMsgBox();
        }
    }
}

function ShowMessage(title, content, btn, onClick) {
    // const dispatch = store.dispatch;
    // dispatch(showMessage({ title, content, btn }));
    msgboxStatus.showCount++;
    msgboxStatus.showWaiting = false;
    msgboxStatus.showMessage = true;
    msgboxStatus.title = title;
    msgboxStatus.content = content;
    msgboxStatus.btn = btn;
    msgboxStatus.onClick = onClick;
    if (msgboxStatus.showCount === 1) {
        mountMsgBox();
    } else {
        renderMsgBox();
    }
}

function HideMessage() {
    // const dispatch = store.dispatch;
    // dispatch(hideMessage());
    if (msgboxStatus.showCount > 0) {
        msgboxStatus.showCount--;
        if (msgboxStatus.showCount === 0) {
            msgboxStatus.onClick = null;
            unMountMsgBox();
        }
    }
}

function CloseMsgBox() {
    // const dispatch = store.dispatch;
    // dispatch(hideMessage());
    if (msgboxStatus.showCount > 0) {
        msgboxStatus.showCount = 0;
        msgboxStatus.onClick = null;
        unMountMsgBox();
    }
}

export { MsgBox, ShowWaiting, HideWaiting, ShowMessage, HideMessage, CloseMsgBox };
