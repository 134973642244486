import IdAvatar from '@/components/IdAvatar';
import IdName from '@/components/IdName';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { queryRedFlowerRankListInRange } from '@/network/classapprequest';
import { setFlowerWeekStat } from '@/store/redflowerreducer';
import lodash from 'lodash';
export default function ClassInfo() {
    const flowerInfo = useSelector((state) => state.redFlower);
    const userInfo = useSelector((state) => state.userInfo);
    const dispatch = useDispatch();

    useEffect(() => {
        onInitQueryWeekList();
    }, [userInfo.classInfo]);

    let weekFlowerList = flowerInfo?.class?.week;
    let weekFlowerCount = 0;
    let studentCount = 0;
    if (weekFlowerList) {
        for (let i = 0; i < weekFlowerList.length; i++) {
            weekFlowerCount += weekFlowerList[i].count;
        }
    }
    if (userInfo?.classInfo?.extendInfo) {
        studentCount = userInfo.classInfo.extendInfo.cntStudent;
    }

    async function onInitQueryWeekList() {
        if (!flowerInfo?.class?.week) {
            //不存在周数据，需要获取下
            let res = await queryRedFlowerRankListInRange(userInfo.token, userInfo.classInfo.schoolId, userInfo.classInfo.gradeId, userInfo.classInfo.id, 1, 100);
            if (res && res.result === 0) {
                dispatch(setFlowerWeekStat({ range: 'class', list: res.data }));
            } else {
                console.log(`----queryRedFlowerRankListInRange return code= ${res.result} msg=${res.msg}`);
            }
        }
    }

    function getTopsComponent() {
        let showList = [];
        if (weekFlowerList) {
            let count = weekFlowerList.length;
            if (count > 3) {
                count = 3;
            }
            for (let i = 0; i < count; i++) {
                showList.push(weekFlowerList[i]);
            }
        }
        return (
            <div className="mt-10 flex flex-row justify-around items-center">
                {showList.map((item) => {
                    return (
                        <div key={`top3_${item.student}`}>
                            <IdAvatar className="w-20 h-20 rounded-full mx-auto" nameId={item.student} />
                            <IdName className="text-2xl  text-center mt-4" nameId={item.student} nameType={1} />
                            <p className="text-2xl  text-center mt-4">{item.count}</p>
                        </div>
                    );
                })}
            </div>
        );
    }

    return (
        <div className="w-full">
            <p className="mt-16 text-4xl text-center">班级名称</p>
            <p className="mt-20 text-3xl text-center">班级统计</p>
            <div className="w-10 h-2 bg-blue-800 mx-auto rounded mt-1" />
            <div className="flex flex-row justify-around items-center mt-16">
                <div>
                    <p className="text-3xl  text-center">{studentCount}</p>
                    <p className="text-2xl  text-center">学生人数</p>
                </div>
                <div>
                    <p className="text-3xl  text-center">{weekFlowerCount}</p>
                    <p className="text-2xl  text-center">本周奖励</p>
                </div>
                <div>
                    <p className="text-3xl  text-center">{studentCount > 0 ? lodash.floor(weekFlowerCount / studentCount, 2) : 0}</p>
                    <p className="text-2xl  text-center">人均获得</p>
                </div>
            </div>
            <div className="my-12 border border-stone-600 border-dashed mx-6" />
            <p className="text-2xl  text-center">本周TOP3</p>
            {getTopsComponent()}
        </div>
    );
}
