import config from '@/shared/appconfig.js';
import { post } from '@/apis/request.js';

//请求班级基本信息
export async function queryUserClassInfo(token, classId) {
    let param = {
        classId,
        token
    };
    let url = `${config.banpaiSvr.domain}/classService/api/class/getClassBaseInfoById`;
    let ret = await post(url, param);
    return ret;
}

//请求学校基本信息
export async function queryUserSchoolInfo(token, schoolId) {
    let param = {
        schoolId,
        token
    };
    let url = `${config.banpaiSvr.domain}/classService/api/school/getSchoolById`;
    let ret = await post(url, param);
    return ret;
}

//批量获取指定类型id对应的名称
export async function queryNameFromIds(token, type, ids) {
    let param = {
        token,
        type,
        ids
    };
    let url = `${config.banpaiSvr.domain}/classService/api/common/getNamesFromIds`;
    let ret = await post(url, param);
    return ret;
}

//获取班级全部成员列表
export async function queryMembersFromClassId(token, classId) {
    let param = {
        token,
        classId
    };
    let url = `${config.banpaiSvr.domain}/classService/api/class/getAllClassMember`;
    let ret = await post(url, param);
    return ret;
}

export async function queryLeaveMessageInfo({ token, userId, pageIndex, pageSize } = {}) {
    return await post(`${config.banpaiSvr.domain}/classService/api/message/getLeaveMessageInfo`, {
        token,
        userId,
        pageIndex,
        pageSize
    });
}

export async function queryClassLeaveMsgStatInfo({ token, classId } = {}) {
    return await post(`${config.banpaiSvr.domain}/classService/api/leavemessage/getClassLeaveMsgStatInfo`, {
        token,
        classId
    });
}

export async function queryLeaveMsgListByUserId({ token, userId, peerUserId, pageIndex, pageSize } = {}) {
    return await post(`${config.banpaiSvr.domain}/classService/api/leavemessage/getLeaveMsgListByUserId`, {
        token,
        userId,
        peerUserId,
        pageIndex,
        pageSize
    });
}

export async function queryUserLeaveMsgStatInfo({ token, userId } = {}) {
    return await post(`${config.banpaiSvr.domain}/classService/api/leavemessage/getUserLeaveMsgStatInfo`, {
        token,
        userId
    });
}

export async function sendLeaveMsg({ token, userId, recvUserId, msgType, msgDuration, msgContent } = {}) {
    return await post(`${config.banpaiSvr.domain}/classService/api/leavemessage/sendLeaveMsg`, {
        token,
        userId,
        recvUserId,
        msgType,
        msgDuration,
        msgContent
    });
}

export async function setLeaveMsgReadStatus({ token, userId, sendUserId, msgId } = {}) {
    return await post(`${config.banpaiSvr.domain}/classService/api/leavemessage/setLeaveMsgReadStatus`, {
        token,
        userId,
        sendUserId,
        msgId
    });
}

export async function queryChildParents({ token, userId } = {}) {
    return await post(`${config.banpaiSvr.domain}/classService/api/child/getChildParents`, {
        token,
        userId
    });
}
