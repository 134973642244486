import { useEffect } from 'react';
import config from '@/shared/appconfig';
import CarouselImages from '@/components/Carousel';
import { useSelector } from 'react-redux';
import lodash from 'lodash';
import { useNavigate } from 'react-router-dom';

export default function MomentImages() {
    const navigate = useNavigate();
    const hotMoment = useSelector((state) => state.classMoment?.hotMoment);

    function onClick() {
        navigate('/moment');
    }

    let Images = [];
    for (let i = 0; i < hotMoment.length; i++) {
        if (hotMoment[i].res) {
            let url = lodash.split(hotMoment[i].res, ',', 1);
            url = `${config.resInfo.resUrl}/${url}`;
            Images.push({
                url,
                title: '',
                content: hotMoment[i].content
            });
        }
    }
    return (
        <div className="w-full h-t-h py-2 px-1" onClick={onClick} alt="classMoment">
            <CarouselImages images={Images} />
        </div>
    );
    //return <div className="bg-white/60 self-stretch m-2 h-144 rounded-2xl">{/* <CarouselImages images={Images} /> */}</div>;
}
