import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    class: {},
    grade: {},
    school: {},
    redFlowerCount: 0
};

export const RedFlowerSlice = createSlice({
    name: 'redFlower',
    initialState,
    reducers: {
        setFlowerTodayStat: (state, action) => {
            let { range, list } = action.payload;
            state[range].today = list;
        },
        setFlowerWeekStat: (state, action) => {
            let { range, list } = action.payload;
            state[range].week = list;
        },
        setFlowerMonthStat: (state, action) => {
            let { range, list } = action.payload;
            state[range].month = list;
        },
        setFlowerTermStat: (state, action) => {
            let { range, list } = action.payload;
            state[range].term = list;
        },
        setFlowerYearStat: (state, action) => {
            let { range, list } = action.payload;
            state[range].year = list;
        },
        setFlowerCount: (state, action) => {
            state.redFlowerCount = action.payload;
        }
    }
});

export const { setFlowerTodayStat, setFlowerWeekStat, setFlowerMonthStat, setFlowerTermStat, setFlowerYearStat, setFlowerCount } = RedFlowerSlice.actions;
export default RedFlowerSlice.reducer;
