import ClassInfo from './components/classinfo';
import FlowerRankList from './components/flowerranklist';
import LeaveStatInfo from './components/leavestatinfo';
import MomentImages from './components/momentimages';
import ClassScheduleInfo from './components/classScheduleinfo';
import LeaveMsgInfo from './components/leavemsginfo';
import AttendanceStatInfo from './components/attendancestatinfo';

function HomeContent() {
    //0.739
    //left gap 0.01354
    //  0.268 0.517 0.188
    return (
        <div className="w-full h-content flex flex-row justify-start items-start">
            <div className="w-l-w h-full ml-l-gap flex-none flex flex-col justify-start items-center">
                <ClassInfo />
                <FlowerRankList />
                <AttendanceStatInfo />
            </div>
            <div className="w-c-w  h-full  flex flex-col justify-start items-center">
                <MomentImages />
                <ClassScheduleInfo />
            </div>
            <div className="w-r-w  h-full flex-none  flex flex-col justify-start items-center">
                <LeaveMsgInfo />
                <LeaveStatInfo />
            </div>
        </div>
    );
}

export default HomeContent;
