import Header from '@/components/Header';
import Tailer from '@/components/Tailer';
import Content from './leaveinfocontent'
export default function LeaveInfo() {
    console.log('------------- on LeaveInfo() -------------------');

    return (
        <div className="w-full h-full flex flex-col">
            <Header />
            <Content/>
            <Tailer />
        </div>
    );
}
