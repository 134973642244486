import Avatarpng from '@/assets/images/Avatar.png';
import { useSelector } from 'react-redux';
import { useRef, useEffect } from 'react';
import utils from '@/shared/utils';
import IdName from '@/components/IdName';
import IdAvatar from '@/components/IdAvatar';
import { ChineseNumber } from '@/shared/constants';

export default function ClassInfo({ offset, classList, classType, curClass, dayOfWeek }) {
    const classSchedule = useSelector((state) => state.classSchedule);
    const keepaliveInfo = useSelector((state) => state.keepalive);
    let todayClassesContainer = useRef();

    useEffect(() => {
        todayClassesContainer.current.scrollTop = offset;
    }, [keepaliveInfo.timerId, classSchedule.week, classSchedule.term]);

    function ClassItemInfo({ classInfo, classType, curClass }) {
        let startTime = classInfo.classStart;
        startTime = utils.getTimeStrFromSeconds(startTime);
        let endTime = classInfo.classEnd;
        endTime = utils.getTimeStrFromSeconds(endTime);
        let style = {
            backgroundColor: '#FFFFFFAF'
        };
        if (classInfo.classIndex === curClass && classType === 2) {
            style = {
                backgroundColor: '#E1FBF7',
                border: '1px solid #00A790'
            };
        }
        if (classInfo.subjectId > 0) {
            return (
                <div className="w-10/12 h-28 bg-white rounded-2xl mb-4 flex flex-row items-center pr-4" style={style} key={`classIndex_${classInfo.classIndex}`}>
                    <IdAvatar className="w-16 h-16 flex-none rounded-full mx-4" nameId={classInfo.teacherId} />
                    <div className="grow h-full flex flex-col items-start justify-center">
                        <IdName className="text-3xl" nameType={9} nameId={classInfo.subjectId} />
                        <div className="w-full flex flex-row justify-between text-xl mt-2">
                            <IdName nameType={1} nameId={classInfo.teacherId} />
                            <p>{`第${ChineseNumber[classInfo.classIndex]}节 ${startTime} - ${endTime}`}</p>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="w-10/12 h-28 bg-white rounded-2xl mb-4 flex flex-row items-center pr-4" style={style} key={`classIndex_${classInfo.classIndex}`}>
                    <div className="w-16 h-16 flex-none mx-4" />
                    <div className="grow h-full flex flex-col items-start justify-center">
                        <p className="text-4xl">自习课</p>
                        <div className="w-full flex flex-row justify-between text-xl">
                            <p />
                            <p>{`第${ChineseNumber[classInfo.classIndex]}节 ${startTime} - ${endTime}`}</p>
                        </div>
                    </div>
                </div>
            );
        }
    }

    function getClassItems() {
        let classes = [];
        for (let i = 1; i <= classSchedule.term.classCount; i++) {
            if (classList[`${i}`]) {
                classes.push({
                    ...classList[`${i}`],
                    classStart: classSchedule.term[i].classStart,
                    classEnd: classSchedule.term[i].classEnd
                });
            } else {
                classes.push({
                    classIndex: i,
                    subjectId: -1,
                    teacherId: -1,
                    classStart: classSchedule.term[i].classStart,
                    classEnd: classSchedule.term[i].classEnd
                });
            }
        }

        return (
            <div className="w-full flex flex-col justify-start items-center">
                {classes.map((item) => {
                    return <ClassItemInfo classInfo={item} classType={classType} curClass={curClass} />;
                })}
            </div>
        );
    }

    return (
        <div className="w-full h-full">
            <p className="mt-16 text-4xl text-center">班级名称</p>
            <p className="mt-20 text-3xl text-center">今日课表</p>
            <div className="w-10 h-2 bg-blue-800 mx-auto rounded mt-1" />
            <div className="w-full h-2/3 mt-6 overflow-y-auto" ref={todayClassesContainer}>
                {getClassItems()}
            </div>
        </div>
    );
}
