import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    fontSize: 16
};

export const AppSettingsSlice = createSlice({
    name: 'appSettingsInfo',
    initialState,
    reducers: {
        setAppFontSize: (state, action) => {
            state.fontSize = action.payload;
        }
    }
});

export const { setAppFontSize } = AppSettingsSlice.actions;
export default AppSettingsSlice.reducer;
