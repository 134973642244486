import config from '@/shared/appconfig.js';
import { post } from '@/apis/request.js';
//import globalStore from '@/store/globalstore';

//请求班牌绑定信息
export async function queryBanpaiBindInfo(uuid) {
    let param = {
        uuid
    };

    let url = `${config.banpaiSvr.domain}/app/banpai/queryBanpaiBindInfo`;
    let ret = await post(url, param);
    return ret;
}

//请求班牌登陆
export async function userLogin(uuid, update, appSecret) {
    let param = {
        uuid,
        update,
        appSecret
    };
    let url = `${config.banpaiSvr.domain}/app/banpai/queryBanpaiLoginInfo`;
    let ret = await post(url, param);
    return ret;
}

//保持激活
export async function keepalive(uuid, token) {
    let param = {
        uuid,
        update: Date.now(),
        token
    };
    let url = `${config.banpaiSvr.domain}/app/banpai/keepalive`;
    let ret = await post(url, param);
    return ret;
}
