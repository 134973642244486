import config from '@/shared/appconfig.js';
import { post } from '@/apis/request.js';

//获取指定范围红花统计排行列表
export async function queryRedFlowerRankListInRange(token, schoolId, gradeId, classId, periodType, count) {
    let param = {
        token,
        schoolId,
        gradeId,
        classId,
        type: periodType,
        pageIndex: 0,
        pageSize: count
    };
    let url = `${config.banpaiSvr.domain}/classapp/flower/flowerStat`;
    let ret = await post(url, param);
    return ret;
}

//获取指定范围红旗统计排行列表
export async function queryRedFlagRankListInRange(token, schoolId, gradeId, classId, periodType, count) {
    let param = {
        token,
        schoolId,
        gradeId,
        classId,
        timeType: periodType,
        pageIndex: 0,
        pageSize: count
    };
    let url = `${config.banpaiSvr.domain}/classapp/flag/flagStat`;
    let ret = await post(url, param);
    return ret;
}

//根据限定的范围，获取红花总数
export async function queryRedFlowerCountInRange(token, schoolId, gradeId, classId, timeType) {
    let param = {
        token,
        schoolId,
        gradeId,
        classId,
        timeType
    };
    let url = `${config.banpaiSvr.domain}/classapp/flower/flowerCountStat`;
    let ret = await post(url, param);
    return ret;
}

//根据限定的范围，获取红旗总数
export async function queryRedFlagCountInRange(token, schoolId, gradeId, classId, timeType) {
    let param = {
        token,
        schoolId,
        gradeId,
        classId,
        timeType
    };
    let url = `${config.banpaiSvr.domain}/classapp/flag/flagCountStat`;
    let ret = await post(url, param);
    return ret;
}

//获取指定范围请假统计明细列表
export async function queryClassLeaveStatListInRange(token, schoolId, gradeId, classId, periodType) {
    let param = {
        token,
        schoolId,
        gradeId,
        classId,
        timeType: periodType
    };
    let url = `${config.banpaiSvr.domain}/classapp/leave/leaveStatList`;
    let ret = await post(url, param);
    return ret;
}

//获取指定时间指定范围请假明细
export async function queryClassLeaveDetailListInRange(token, schoolId, gradeId, classId, periodType, count) {
    let param = {
        token,
        schoolId,
        gradeId,
        classId,
        timeType: periodType,
        pageIndex: 0,
        pageSize: count
    };
    let url = `${config.banpaiSvr.domain}/classapp/leave/leaveDetailListInRange`;
    let ret = await post(url, param);
    return ret;
}

//获取指定范围风采列表
export async function queryClassMomentListInRange(token, schoolId, gradeId, classId, days, count) {
    let endTime = Date.now();
    let startTime = endTime - days * 24 * 3600;
    let param = {
        token,
        schoolId,
        gradeId,
        classId,
        userId: 0,
        startTime,
        endTime,
        pageIndex: 0,
        pageSize: count
    };
    let url = `${config.banpaiSvr.domain}/classapp/moment/interact/VisitHot`;
    let ret = await post(url, param);
    return ret;
}

export async function queryMomentList({ token, schoolId, gradeId, classId, pageIndex, pageSize } = {}) {
    return await post('/classapp/moment/momentList', {
        token,
        schoolId,
        gradeId,
        classId,
        userId: 0,
        timeType: 0,
        level: 0,
        sortType: 0,
        keyword: '',
        topics: [],
        pageIndex,
        pageSize
    });
}

export const queryMomentGet = async (token, momentId) => {
    return await post('/classapp/moment/momentGet', { token, momentId });
};

export const queryCommentList = async ({ token, momentId, pageIndex, pageSize } = {}) => {
    return await post('/classapp/moment/interact/commentList', {
        token,
        momentId,
        pageIndex,
        pageSize
    });
};

export const queryFavoriteUserList = async ({ token, momentId, pageIndex, pageSize } = {}) => {
    return await post('/classapp/moment/interact/FavoriteUserList', {
        token,
        momentId,
        pageIndex,
        pageSize
    });
};

//获取风采统计数据
export async function queryClassMomentStatInfo(token, schoolId, gradeId, classId, timeType) {
    let param = {
        token,
        schoolId,
        gradeId,
        classId,
        timeType
    };
    let url = `${config.banpaiSvr.domain}/classapp/moment/getClassMomentStatInfo`;
    let ret = await post(url, param);
    return ret;
}

//获取指定班级本周课程表信息
export async function queryClassWeekScheduleInfo(token, schoolId, gradeId, classId) {
    let param = {
        token,
        schoolId,
        gradeId,
        classId
    };
    let url = `${config.banpaiSvr.domain}/app/classschedule/querySchoolClassWeekScheduleInfo`;
    let ret = await post(url, param);
    return ret;
}

//获取学期信息
export async function querySchoolTermInfo(token, schoolId) {
    let param = {
        token,
        schoolId
    };
    let url = `${config.banpaiSvr.domain}/app/classschedule/querySchoolTermInfo`;
    let ret = await post(url, param);
    return ret;
}

//获取指定地域天气信息
export async function querySchoolWeatherInfo(token, provinceId, cityId, countyId) {
    let param = {
        token,
        provinceId,
        cityId,
        countyId
    };
    let url = `${config.banpaiSvr.domain}/app/banpai/queryWeatherInfoByAreaInfo`;
    let ret = await post(url, param);
    return ret;
}

//获取班级学生绑卡信息
export async function queryClassStudentCardBindInfo(token, classId) {
    let param = {
        token,
        classId
    };
    let url = `${config.banpaiSvr.domain}/app/banpai/device/queryClassStudentCardBindInfo`;
    let ret = await post(url, param);
    return ret;
}
