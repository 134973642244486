import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    today: {
        stat: {
            attance: 23,
            total: 32,
            absent: 3,
            late: 2
        },
        detail: [
            {
                userId: 1000120,
                type: 0,
                cardType: 0,
                cardNo: '00123234123',
                time: 1700096491
            },
            {
                userId: 1000121,
                type: 1,
                time: 1700096592
            }
        ]
    }
};

export const AttendanceSlice = createSlice({
    name: 'attendance',
    initialState,
    reducers: {
        setAttendanceTodayStat: (state, action) => {
            state.today.stat = action.payload;
        },
        setAttendanceTodayDetail: (state, action) => {
            state.today.detail = action.payload;
        },
        addAttendanceTodayDetail: (state, action) => {
            if (!state.today.detail) {
                state.today.detail = [];
            }
            state.today.detail = [action.payload, ...state.today.detail];
        }
    }
});

export const { setAttendanceTodayStat, setAttendanceTodayDetail, addAttendanceTodayDetail } =
    AttendanceSlice.actions;
export default AttendanceSlice.reducer;
