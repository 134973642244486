import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from '@/pages/app';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';
import VConsole from 'vconsole';

if (process.env.NODE_ENV === 'development') {
    // new VConsole();
}

window.addEventListener('contextmenu', (e) => {
    e.preventDefault();
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);
