import Avatarpng from '@/assets/images/Avatar.png';
import classIconpng from '@/assets/images/classIcon.png';
import flowericonpng from '@/assets/images/flowericon.png';
import redFlagiconpng from '@/assets/images/redFlagicon.png';
import redflagpng from '@/assets/images/redFlag.png';
import { useSelector } from 'react-redux';
import { UserRoles } from '@/shared/constants';
import IdName from '@/components/IdName';
import IdAvatar from '@/components/IdAvatar';

export default function ClassInfo() {
    const userInfo = useSelector((state) => state.userInfo);
    const redFlagInfo = useSelector((state) => state.redFlag);
    const redFlowerInfo = useSelector((state) => state.redFlower);
    let classAdmin = userInfo?.classInfo?.admin;
    let classAdminItem = null;
    if (classAdmin) {
        classAdmin.map((item) => {
            if (item.role & UserRoles.master) {
                classAdminItem = item;
            }
        });
    }
    return (
        <div className="w-full h-lt-h px-3 py-2">
            <div className="bg-white/60 w-full h-full rounded-2xl flex flex-row items-center justify-between px-6">
                <div className="flex flex-col justify-center items-start">
                    <div className="flex flex-row mb-8  ">
                        <img className="ml-3 w-9 h-8" src={classIconpng}></img>
                        <p className="ml-9 text-3xl">{userInfo?.classInfo?.name}</p>
                    </div>
                    <div className="flex flex-row">
                        <IdAvatar className="w-16 h-16 rounded-4xl mr-5" nameId={classAdminItem ? classAdminItem.userId : 0} />
                        <div>
                            <IdName className="text-2xl" nameId={classAdminItem ? classAdminItem.userId : 0} nameType={1} />
                            <div className="flex flex-row mt-2">
                                <img className="w-9 h-9 mr-2" src={flowericonpng}></img>
                                <p className="text-2xl mr-8">{redFlowerInfo.redFlowerCount}</p>
                                <img className="w-7 h-9 mr-2" src={redFlagiconpng}></img>
                                <p className="text-2xl">{redFlagInfo.redFlagCount}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="w-40" src={redflagpng}></img>
            </div>
        </div>
    );
}
